<template>
  <div class="product">
    <van-form
      @submit="onSubmit"
      @failed="onFailed"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      ref="form"
    >
      <van-collapse v-model="activeNames">
        <van-collapse-item size="large" title="产品配置" name="1">
          <ul class="product-list">
            <!-- 多选 -->
            <div
              v-for="(item, index) in requiredMultipleList"
              :key="item.series"
            >
              <li class="product-item" v-if="item.list[index].name !== '右手位' && item.list[index].name !== '左手位' && item.list[index].name !== '诊断床，33°旋转不带ICC和AQC' && item.list[index].name !== '诊断床，33°旋转带ICC和AQC'" >
                <span class="item-name alonepoint">{{ item.series }}</span>
                <span
                  class="item-btn"
                  @click="chooseParts(item.series, item, index)"
                >
                  {{ requiredMultipleList[index].deviceUiValue }}</span
                >
              </li>
            </div>
            <!-- 选配单选 -->

            <li
              class="product-item"
              v-for="(item, index) in optionalRadioList"
              :key="index"
            >
              <span class="item-name">{{ item.name }}</span>
              <span class="item-btn"
                ><van-switch
                  v-model="item.isHave"
                  active-color="#EC6602"
                  inactive-color="#AAAAAA"
                  @change="switchChange(item.isHave, item.id, item)"
              /></span>
            </li>
          </ul>
        </van-collapse-item>
        <ul class="product-list alonebgc">
          <li class="product-item alone">
            <span class="item-name alonepoint">定位点</span>
            <span
              class="item-btn"
              @click="choosePoint('定位点', dataObj.point)"
            >
              {{ dataObj.point }}</span
            >
          </li>
        </ul>

        <van-collapse-item size="large" title="机房设置(单位:cm)" name="3">
          <van-field
            input-align="right"
            :border="false"
            v-model.number="dataObj.machineRoomForm.length"
            readonly
            name="机房长度"
            label="机房长度(X)"
            placeholder="未输入"
            @focus="focusEvent('机房长度(X)')"
            type="number"
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value <=
                    JSON.parse(errorObj.examinationRoom_SideLength)[1] &&
                    value >= JSON.parse(errorObj.examinationRoom_SideLength)[0]
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
          />
          <!-- 输入有误，请检查! -->
          <span class="danger-item" v-if="lengthDangerShow">{{
            dangers.effectiveAreaTips
          }}</span>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.width"
            name="机房宽度"
            label="机房宽度(Y)"
            placeholder="未输入"
            @focus="focusEvent('机房宽度(Y)')"
            @onBlur="
              machineWidthBlur('机房宽度(Y)', dataObj.machineRoomForm.width)
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value <=
                    JSON.parse(this.errorObj.examinationRoom_SideLength)[1] &&
                    value >=
                      JSON.parse(this.errorObj.examinationRoom_SideLength)[0]
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <span class="danger-item" v-if="widthDangerShow">{{
            dangers.effectiveAreaTips
          }}</span>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model="dataObj.machineRoomForm.structureHeight"
            name="机房结构高度"
            label="机房结构高度"
            placeholder="未输入"
            @focus="focusEvent('机房结构高度')"
            @onBlur="
              structureHeightBlur(
                '机房结构高度',
                dataObj.machineRoomForm.structureHeight
              )
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value.replace(/[^0-9]/gi, '') <=
                    errorObj.computerRoom_MaximumHeight &&
                    value.replace(/[^0-9]/gi, '') >=
                      errorObj.computerRoom_MinimumHeight
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
          />
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model="dataObj.machineRoomForm.ceilingHeight"
            name="机房假天花高度"
            label="机房假天花高度"
            placeholder="未输入"
            @focus="focusEvent('机房假天花高度')"
            @onBlur="
              ceilingHeightBlur(
                '机房假天花高度',
                dataObj.machineRoomForm.ceilingHeight
              )
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value.replace(/[^0-9]/gi, '') <=
                    errorObj.false_Platfond_MaximumHeight &&
                    value.replace(/[^0-9]/gi, '') >=
                      errorObj.false_Platfond_MinimumHeight
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
          />
          <span class="danger-item" v-if="ceilingHeightDangerShow">{{
            dangers.false_Platfond_Tips
          }}</span>
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">病患通道方位</span>
                <span
                  class="item-btn"
                  @click="choosePatientPosition('病患通道方位')"
                >
                  {{ dataObj.machineRoomForm.patientPosition }}</span
                >
              </li>
            </ul>
          </van-cell>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.patientLocation"
            name="病患通道位置"
            label="病患通道位置"
            placeholder="未输入"
            @focus="focusEvent('病患通道位置')"
            @onBlur="
              patientLocationBlur(
                '病患通道位置',
                dataObj.machineRoomForm.patientLocation
              )
            "
            :rules="[
              {
                validator: (value) => {
                  if (
                    dataObj.machineRoomForm.patientPosition == '上' ||
                    dataObj.machineRoomForm.patientPosition == '下'
                  ) {
                    return value <=
                      dataObj.machineRoomForm.length -
                        dataObj.machineRoomForm.patientWidth && value >= 0
                      ? true
                      : false;
                  } else {
                    return value <=
                      dataObj.machineRoomForm.width -
                        dataObj.machineRoomForm.patientWidth && value >= 0
                      ? true
                      : false;
                  }
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.patientWidth"
            name="病患通道宽度"
            label="病患通道宽度"
            placeholder="未输入"
            @focus="focusEvent('病患通道宽度')"
            @onBlur="
              patientWidthBlur(
                '病患通道宽度',
                dataObj.machineRoomForm.patientWidth
              )
            "
            :rules="[
              {
                validator: (value) => {
                  if (
                    dataObj.machineRoomForm.patientPosition == '上' ||
                    dataObj.machineRoomForm.patientPosition == '下'
                  ) {
                    return value <= Number(dataObj.machineRoomForm.length) &&
                      value >= 0
                      ? true
                      : false;
                  } else {
                    return value <= Number(dataObj.machineRoomForm.width) &&
                      value >= 0
                      ? true
                      : false;
                  }
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <span class="danger-item" v-if="patientWidthDangerShow">{{
            dangers.checkRoomTips
          }}</span>
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">病患通道类型</span>
                <span
                  class="item-btn"
                  @click="choosePatientType('病患通道类型')"
                >
                  {{ dataObj.machineRoomForm.patientType }}</span
                >
              </li>
            </ul>
          </van-cell>
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">病患通道朝向</span>
                <span
                  class="item-btn"
                  @click="choosePatientDirection('病患通道朝向')"
                >
                  {{ dataObj.machineRoomForm.patientDirection }}</span
                >
              </li>
            </ul>
          </van-cell>
          <ul class="product-list" v-if="slideDoorShow">
            <li class="product-item">
              <span class="item-name">平移门滑动方向</span>
              <span
                class="item-btn"
                @click="chooseSlideDirection('平移门滑动方向')"
              >
                {{ dataObj.machineRoomForm.slideDirection }}</span
              >
            </li>
          </ul>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.controlRoomForm.windowLocation"
            name="观察窗位置"
            label="观察窗位置"
            placeholder="未输入"
            @focus="focusEvent('观察窗位置')"
            @onBlur="
              windowLocationBlur(
                '观察窗位置',
                dataObj.controlRoomForm.windowLocation
              )
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value <=
                    dataObj.machineRoomForm.length -
                      dataObj.controlRoomForm.windowWidth && value >= 0
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.controlRoomForm.windowWidth"
            name="观察窗宽度"
            label="观察窗宽度"
            placeholder="未输入"
            @focus="focusEvent('观察窗宽度')"
            @onBlur="
              windowWidthBlur('观察窗宽度', dataObj.controlRoomForm.windowWidth)
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  if (!isHaveKong) {
                    //没有控制室
                    return value <=
                      dataObj.machineRoomForm.length -
                        dataObj.controlRoomForm.windowLocation &&
                      value >= errorObj.observationWindow_Weight_Minimum
                      ? true
                      : false;
                  } else {
                    //有控制室
                    if (
                      dataObj.controlRoomForm.location &&
                      dataObj.controlRoomForm.location == 0
                    ) {
                      return value <=
                        dataObj.machineRoomForm.length -
                          dataObj.controlRoomForm.windowLocation &&
                        value <=
                          dataObj.controlRoomForm.length -
                            dataObj.controlRoomForm.windowLocation &&
                        value >= errorObj.observationWindow_Weight_Minimum
                        ? true
                        : false;
                    } else if (
                      dataObj.controlRoomForm.location &&
                      dataObj.controlRoomForm.location > 0
                    ) {
                      return value <=
                        dataObj.controlRoomForm.location +
                          dataObj.controlRoomForm.length -
                          dataObj.controlRoomForm.windowLocation &&
                        value <=
                          dataObj.machineRoomForm.length -
                            dataObj.controlRoomForm.windowLocation &&
                        value >= errorObj.observationWindow_Weight_Minimum
                        ? true
                        : false;
                    } else {
                      return value <=
                        dataObj.controlRoomForm.location +
                          dataObj.controlRoomForm.length -
                          dataObj.controlRoomForm.windowLocation &&
                        value <=
                          dataObj.machineRoomForm.length -
                            dataObj.controlRoomForm.windowLocation &&
                        value >= errorObj.observationWindow_Weight_Minimum
                        ? true
                        : false;
                    }
                  }
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <span class="danger-item" v-if="windowWidthDangerShow">{{
            dangers.observationWindow_Tips
          }}</span>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.technicianLocation"
            name="技师通道位置"
            label="技师通道位置"
            placeholder="未输入"
            @focus="focusEvent('技师通道位置')"
            @onBlur="
              technicianLocationBlur(
                '技师通道位置',
                dataObj.machineRoomForm.technicianLocation
              )
            "
            :rules="[
              {
                validator: (value) => {
                  return value <= dataObj.machineRoomForm.length && value >= 0
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <!-- <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.technicianLocation"
            name="技师通道位置"
            label="技师通道位置"
            placeholder="未输入"
            @focus="focusEvent('技师通道位置')"
            @onBlur="
              technicianLocationBlur(
                '技师通道位置',
                dataObj.machineRoomForm.technicianLocation
              )
            "
            :rules="[
              {
                validator: (value) => {
                  return value <=
                    dataObj.machineRoomForm.length -
                      dataObj.machineRoomForm.technicianWidth && value >= 0
                    ? true
                    : false
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!'
              }
            ]"
            type="number"
          /> -->
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.technicianWidth"
            name="技师通道宽度"
            label="技师通道宽度"
            placeholder="未输入"
            @focus="focusEvent('技师通道宽度')"
            @onBlur="
              technicianWidthBlur(
                '技师通道宽度',
                dataObj.machineRoomForm.technicianWidth
              )
            "
            :rules="[
              {
                validator: (value) => {
                  return value + dataObj.machineRoomForm.technicianLocation <=
                    dataObj.machineRoomForm.length && value >= 0
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <!-- <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.technicianWidth"
            name="技师通道宽度"
            label="技师通道宽度"
            placeholder="未输入"
            @focus="focusEvent('技师通道宽度')"
            @onBlur="
              technicianWidthBlur(
                '技师通道宽度',
                dataObj.machineRoomForm.technicianWidth
              )
            "
            :rules="[
              {
                validator: (value) => {
                  if (!isHaveKong) {
                    //没有控制室
                    return value <=
                      dataObj.machineRoomForm.length -
                        dataObj.machineRoomForm.technicianLocation && value >= 0
                      ? true
                      : false
                  } else {
                    //有控制室
                    if (
                      dataObj.controlRoomForm.location &&
                      dataObj.controlRoomForm.location == 0
                    ) {
                      return value <=
                        dataObj.machineRoomForm.length -
                          dataObj.machineRoomForm.technicianLocation &&
                        value <=
                          dataObj.controlRoomForm.length -
                            dataObj.machineRoomForm.technicianLocation &&
                        value >= 0
                        ? true
                        : false
                    } else if (
                      dataObj.controlRoomForm.location &&
                      dataObj.controlRoomForm.location > 0
                    ) {
                      return value <=
                        dataObj.controlRoomForm.location +
                          dataObj.controlRoomForm.length -
                          dataObj.machineRoomForm.technicianLocation &&
                        value <=
                          dataObj.machineRoomForm.length -
                            dataObj.machineRoomForm.technicianLocation &&
                        value >= errorObj.observationWindow_Weight_Minimum
                        ? true
                        : false
                    } else {
                      return value <=
                        dataObj.controlRoomForm.location +
                          dataObj.controlRoomForm.length -
                          dataObj.machineRoomForm.technicianLocation &&
                        value <=
                          dataObj.machineRoomForm.length -
                            dataObj.machineRoomForm.technicianLocation &&
                        value >= errorObj.observationWindow_Weight_Minimum
                        ? true
                        : false
                    }
                  }
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!'
              }
            ]"
            type="number"
          /> -->
          <span class="danger-item" v-show="technicianWidthDangerShow">{{
            dangers.technicianAccessTips
          }}</span>
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">技师通道朝向</span>
                <span
                  class="item-btn"
                  @click="chooseTechnicianDirection('技师通道朝向')"
                >
                  {{ dataObj.machineRoomForm.technicianDirection }}</span
                >
              </li>
            </ul>
          </van-cell>
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">技师通道类型</span>
                <span
                  class="item-btn"
                  @click="chooseTechnicianType('技师通道类型')"
                >
                  {{ dataObj.machineRoomForm.technicianType }}</span
                >
              </li>
            </ul>
          </van-cell>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.wallThickness"
            name="墙厚"
            label="墙厚"
            placeholder="未输入"
            @focus="focusEvent('墙厚')"
            @onBlur="
              wallThicknessBlur('墙厚', dataObj.machineRoomForm.wallThickness)
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value <= errorObj.wall_Maximum &&
                    value >= errorObj.wall_Minimum
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.corridorWidth"
            name="走廊宽度"
            label="走廊宽度"
            placeholder="未输入"
            @focus="focusEvent('走廊宽度')"
            @onBlur="
              corridorWidthBlur(
                '走廊宽度',
                dataObj.machineRoomForm.corridorWidth
              )
            "
            :rules="[
              {
                validator: (value) => {
                  return value <= errorObj.corridorWeight_Maximum && value >= 0
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.machineRoomForm.decorateThickness"
            name="装饰层厚"
            label="装饰层厚"
            placeholder="未输入"
            @focus="focusEvent('装饰层厚')"
            @onBlur="
              decorateThicknessBlur(
                '装饰层厚',
                dataObj.machineRoomForm.decorateThickness
              )
            "
            :rules="[
              {
                validator: (value) => {
                  return value <= errorObj.decorativeLayer_Maximum && value >= 0
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
        </van-collapse-item>
        <div class="kongcollapse">
          <span class="title">有无控制室</span>
          <span class="zhuSwitch"
            ><van-switch
              v-model="kongChecked"
              active-color="#EC6602"
              inactive-color="#AAAAAA"
              @change="switchChangeKong"
          /></span>
        </div>
        <van-collapse-item
          v-if="isHaveKong"
          size="large"
          title="控制室设置(单位:cm)"
          name="4"
        >
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.controlRoomForm.length"
            name="控制室长度"
            label="控制室长度(X)"
            placeholder="未输入"
            @focus="focusEvent('控制室长度(X)')"
            @onBlur="
              controlLengthBlur('控制室长度(X)', dataObj.controlRoomForm.length)
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value <=
                    JSON.parse(this.errorObj.controlRoom_SideLength)[1] &&
                    value >= JSON.parse(this.errorObj.controlRoom_SideLength)[0]
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <span class="danger-item" v-if="controlLengthDangerShow">{{
            dangers.controlRoom_Tips
          }}</span>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.controlRoomForm.width"
            name="控制室宽度"
            label="控制室宽度(Y)"
            placeholder="未输入"
            @focus="focusEvent('控制室宽度(Y)')"
            @onBlur="
              controlWidthBlur('控制室宽度(Y)', dataObj.controlRoomForm.width)
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value <=
                    JSON.parse(this.errorObj.controlRoom_SideLength)[1] &&
                    value >= JSON.parse(this.errorObj.controlRoom_SideLength)[0]
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <span class="danger-item" v-if="controlWidthDangerShow">{{
            dangers.controlRoom_Tips
          }}</span>
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">控制室门类型</span>
                <span class="item-btn" @click="chooseDoorType('控制室门类型')">
                  {{ dataObj.controlRoomForm.doorType }}</span
                >
              </li>
            </ul>
          </van-cell>
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">控制室门方位</span>
                <span
                  class="item-btn"
                  @click="chooseDoorPosition('控制室门方位')"
                >
                  {{ dataObj.controlRoomForm.doorPosition }}</span
                >
              </li>
            </ul>
          </van-cell>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.controlRoomForm.doorLocation"
            name="控制室门位置"
            label="控制室门位置"
            placeholder="未输入"
            @focus="focusEvent('控制室门位置')"
            @onBlur="
              doorLocationBlur(
                '控制室门位置',
                dataObj.controlRoomForm.doorLocation
              )
            "
            :rules="[
              {
                validator: (value) => {
                  if (dataObj.controlRoomForm.doorPosition == '下') {
                    return value <=
                      Number(dataObj.machineRoomForm.length) -
                        Number(dataObj.controlRoomForm.doorWidth) && value >= 0
                      ? true
                      : false;
                  } else {
                    return value <=
                      Number(dataObj.controlRoomForm.width) -
                        Number(dataObj.controlRoomForm.doorWidth) && value >= 0
                      ? true
                      : false;
                  }
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.controlRoomForm.doorWidth"
            name="控制室门宽度"
            label="控制室门宽度"
            placeholder="未输入"
            @focus="focusEvent('控制室门宽度')"
            @onBlur="
              doorWidthBlur('控制室门宽度', dataObj.controlRoomForm.doorWidth)
            "
            :rules="[
              {
                validator: (value) => {
                  if (dataObj.controlRoomForm.doorPosition == '下') {
                    return value <= dataObj.controlRoomForm.length && value >= 0
                      ? true
                      : false;
                  } else {
                    return value <= dataObj.controlRoomForm.width && value >= 0
                      ? true
                      : false;
                  }
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
          <van-cell :border="false">
            <ul class="product-list">
              <li class="product-item product-item1">
                <span class="item-name">控制室门朝向</span>
                <span
                  class="item-btn"
                  @click="chooseDoorDirection('控制室门朝向')"
                >
                  {{ dataObj.controlRoomForm.doorDirection }}</span
                >
              </li>
            </ul>
          </van-cell>
          <van-field
            input-align="right"
            :border="false"
            readonly
            v-model.number="dataObj.controlRoomForm.location"
            name="控制室位置"
            label="控制室位置"
            placeholder="未输入"
            @focus="focusEvent('控制室位置')"
            @onBlur="
              controlLocationBlur(
                '控制室位置',
                dataObj.controlRoomForm.location
              )
            "
            :rules="[
              {
                required: true,
                validator: (value) => {
                  return value <= dataObj.machineRoomForm.length &&
                    value >= -Number(dataObj.controlRoomForm.length)
                    ? true
                    : false;
                },
                trigger: 'onSubmit',
                message: '超过限定值，请重新输入数据!',
              },
            ]"
            type="number"
          />
        </van-collapse-item>
        <!-- 柱子 -->
        <div class="zhucollapse">
          <span class="title">有无柱子</span>
          <span class="zhuSwitch"
            ><van-switch
              v-model="zhuChecked"
              active-color="#EC6602"
              inactive-color="#AAAAAA"
              @change="switchChangeZhu"
          /></span>
        </div>

        <div class="haveZhu" v-show="isHaveZhu">
          <ul class="zhu-list">
            <li style="padding-right: 10px">
              <van-field
                input-align="right"
                label="柱子数量"
                :border="false"
                v-model.number="zhuNum"
                placeholder="未输入"
                @input="zhuBlur"
                type="number"
              />
            </li>
            <div v-for="(item, index) in templateList" :key="index">
              <Column @setZhuParams="setZhuParams(item)">{{ item }}</Column>
            </div>
          </ul>
        </div>
        <ul class="product-list alonebgc">
          <li class="product-item alone">
            <span class="item-name alonepoint">布线方式</span>
            <span class="item-btn" @click="chooseWiring('布线方式')">
              {{ dataObj.wiring }}</span
            >
          </li>
        </ul>
      </van-collapse>
      <van-button
        round
        block
        type="info"
        class="nextStepBtn"
        native-type="submit"
        >下一步</van-button
      >
      <FieldPopup ref="roomFieldRef" @closeIcon="closeIcon">
        <!-- 房间输入框 -->
        <ul class="popup-content">
          <li class="title">{{ popupTitle }}</li>
          <li class="desc">请输入{{ popupTitle }}</li>
          <li class="value">
            <van-field
              @keydown.enter="enterEvent"
              input-align="center"
              :border="false"
              @focus="paramsPopupFocus($event)"
              size="large"
              v-model="fieldValue"
              placeholder="未输入"
              ref="focusRef"
              :type="fieldType"
            />
          </li>
          <li class="line"></li>
          <li class="ruleText" v-if="ruleShow">
            {{
              regularField == -1
                ? "请输入数字或合法的运算表达式"
                : "超过限定值，请重新输入数据!"
            }}
          </li>
          <li class="content-btn" @click="roomFieldConfirm">确定</li>
        </ul>
      </FieldPopup>
      <!-- 配件弹窗 -->
      <DevicePopup ref="devicePopupRef" @closeIcon="closeIcon">
        <div v-for="(item, index) in requiredMultipleList" :key="index">
          <div>
            <ul class="popup-content" v-if="partsPopup == item.series">
              <li class="title">{{ popupContnet.title }}</li>
              <li class="desc1" v-if="!dangerShow">
                请选择{{ popupContnet.title }}
              </li>
              <li
                v-for="(ele, index) in item.list"
                :key="index"
                class="content-item"
                @click="choseDeviceType(item, index, ele)"
              >
                {{ ele.name }}
                <span :class="{ checked: checkMark == index }"></span>
              </li>
              <li class="content-btn" @click="devicePopupConfirm">确定</li>
            </ul>
          </div>
        </div>
      </DevicePopup>
      <!-- 参数弹框 -->
      <VanPopup ref="popupRef" @closeIcon="closeIcon">
        <!-- 房间选项-->
        <ul class="popup-content" v-if="!ZhuZi && !dangerShow">
          <li class="title">{{ popupContnet.title }}</li>
          <li class="desc1">请选择{{ popupContnet.title }}</li>
          <li
            class="content-item"
            @click="choseType(item, index)"
            v-for="(item, index) in popupContnet.types"
            :key="index"
          >
            {{ item }}
            <span :class="{ checked: checkMark == index }"></span>
          </li>
          <li class="content-btn" @click="popupConfirm">确定</li>
        </ul>
        <!-- 风险提示 -->
        <div class="popup-content danger" v-if="dangerShow">
          <p class="title">风险提示</p>
          <div class="contentBox">
            <p
              class="danger-item"
              v-for="(item, index) in dataObj.paramsAlarmList"
              :key="index"
            >
              {{ index + 1 }}.{{ item.message }}
            </p>
          </div>
          <div style="display: flex; width: 80%; margin: 0 auto">
            <li class="content-btn cancel" @click="dangerCancel">取消</li>
            <li class="content-btn zhubtn" @click="dangerConfirm">确定</li>
          </div>
        </div>
      </VanPopup>
    </van-form>
    <!-- 柱子弹框 -->
    <van-form
      @submit="onSubmitZhu"
      @failed="onFailedZhu"
      ref="form"
      :submit-on-enter="false"
      scroll-to-error
    >
      <VanPopup ref="popupRef1" @closeIcon="closeIcon">
        <ul class="popup-content" v-if="ZhuZi">
          <li class="title">设置柱子{{ zhuIndex }}</li>
          <li class="zhuInput">
            <van-field
              autocomplete="off"
              input-align="right"
              :border="false"
              v-model.number="zhuParamsForm.zhuLocationX"
              name="柱子位置(X)"
              label="柱子位置(X)"
              placeholder="未输入"
              @blur="
                zhuLocationXBlur('柱子位置(X)', zhuParamsForm.zhuLocationX)
              "
              :rules="[
                {
                  required: true,
                  validator: (value) => {
                    if (dataObj.point == '左上角') {
                      if (
                        dataObj.machineRoomForm.length !== '' &&
                        zhuParamsForm.zhuLength !== ''
                      ) {
                        return value <=
                          Number(dataObj.machineRoomForm.length) -
                            zhuParamsForm.zhuLength &&
                          value >= errorObj.column_X_Minimum
                          ? true
                          : false;
                      }
                    } else {
                      if (
                        dataObj.machineRoomForm.length !== '' &&
                        zhuParamsForm.zhuLength !== ''
                      ) {
                        return value <=
                          Number(dataObj.machineRoomForm.length) &&
                          value >= errorObj.column_X_Minimum
                          ? true
                          : false;
                      }
                    }
                  },
                  trigger: 'onBlur',
                  message: '超过限定值，请重新输入数据!',
                },
              ]"
              type="number"
            />
            <van-field
              autocomplete="off"
              input-align="right"
              :border="false"
              v-model.number="zhuParamsForm.zhuLocationY"
              name="柱子位置(Y)"
              label="柱子位置(Y)"
              placeholder="未输入"
              @blur="
                zhuLocationYBlur('柱子位置(Y)', zhuParamsForm.zhuLocationY)
              "
              :rules="[
                {
                  required: true,
                  validator: (value) => {
                    if (
                      dataObj.machineRoomForm.width !== '' &&
                      zhuParamsForm.zhuWidth !== ''
                    ) {
                      return value <=
                        Number(dataObj.machineRoomForm.width) +
                          Number(dataObj.controlRoomForm.width) +
                          Number(dataObj.machineRoomForm.wallThickness) -
                          zhuParamsForm.zhuWidth &&
                        value >= errorObj.column_Y_Minimum
                        ? true
                        : false;
                    }
                  },
                  trigger: 'onBlur',
                  message: '超过限定值，请重新输入数据!',
                },
              ]"
              type="number"
            />
            <van-field
              autocomplete="off"
              input-align="right"
              :border="false"
              v-model.number="zhuParamsForm.zhuLength"
              name="柱子长度"
              label="柱子长度(x)"
              placeholder="未输入"
              @blur="zhuLengthBlur('柱子长度', zhuParamsForm.zhuLength)"
              :rules="[
                {
                  required: true,
                  validator: (value) => {
                    if (
                      dataObj.machineRoomForm.length !== '' &&
                      zhuParamsForm.zhuLocationX !== ''
                    ) {
                      return value <= Number(dataObj.machineRoomForm.length) &&
                        value >= errorObj.column_SideLength_Minimum
                        ? true
                        : false &&
                            dataObj.machineRoomForm.wallThickness +
                              zhuParamsForm.zhuLocationX >=
                              value &&
                            dataObj.machineRoomForm.wallThickness +
                              zhuParamsForm.zhuLocationY >=
                              value;
                    }
                  },
                  message: '超过限定值，请重新输入数据!',
                  trigger: 'onBlur',
                },
              ]"
              type="number"
            />
            <van-field
              autocomplete="off"
              input-align="right"
              :border="false"
              v-model.number="zhuParamsForm.zhuWidth"
              name="柱子宽度"
              label="柱子宽度(y)"
              placeholder="未输入"
              @blur="zhuWidthBlur('柱子宽度', zhuParamsForm.zhuWidth)"
              :rules="[
                {
                  required: true,
                  validator: (value) => {
                    if (
                      dataObj.machineRoomForm.width !== '' &&
                      zhuParamsForm.zhuLocationY !== ''
                    ) {
                      return value <= Number(dataObj.machineRoomForm.width) &&
                        value >= errorObj.column_SideLength_Minimum
                        ? true
                        : false &&
                            dataObj.machineRoomForm.wallThickness +
                              zhuParamsForm.zhuLocationX >=
                              value &&
                            dataObj.machineRoomForm.wallThickness +
                              zhuParamsForm.zhuLocationY >=
                              value;
                    }
                  },
                  message: '超过限定值，请重新输入数据!',
                  trigger: 'onChange',
                },
              ]"
              type="number"
            />
          </li>
          <div style="display: flex; width: 80%; margin: 0 auto">
            <li class="content-btn cancel" @click="zhuCancel">取消</li>
            <van-button
              round
              block
              type="info"
              class="content-btn zhubtn"
              native-type="submit"
              >确定</van-button
            >
          </div>
        </ul>
      </VanPopup>
    </van-form>
  </div>
</template>

<script>
import VanPopup from "./../components/VanPopup.vue";
import FieldPopup from "./../components/FieldPopup.vue";
import DevicePopup from "./../components/DevicePopup.vue";
import Column from "./../components/Column";
import { GetProjectInfo } from "./../api/product";
import {
  Auth,
  GetDevicesByName,
  GetDeviceTypeByNameWithApp,
  GetModelLimitByName,
  GetModelTipLimitByName,
} from "./../api/product";
import { mapMutations } from "vuex";
import { Dialog } from "vant";
import { AES } from "@/plugins/md5.js";
import baseUrlApi from "./../api/baseUrl";
import axios from "axios";
export default {
  name: "Product",
  components: { VanPopup, FieldPopup, DevicePopup, Column },
  data() {
    return {
      ThirdPartyProjectId: "",
      systemName: "",
      userToken: "",
      timer: null,
      activeNames: ["1", "3", "4"],
      // 配件数据
      deviceSeriesResult: {},
      deviceTypeResult: [],
      // 选配单选
      optionalRadioList: [],
      // 多选
      requiredMultipleList: [],
      zhuChecked: false,
      kongChecked: true,
      isHaveZhu: false,
      isHaveKong: true,
      slideDoorShow: false,
      fieldValue: null,
      fieldName: "",
      popupTitle: "",
      ruleShow: false,
      typePopupShow: true,
      popupContnet: {
        title: "",
        types: [],
      },
      couchTypes: ["Vario1", "Vario2", "Vario3"],
      workstationTypes: ["无", "西门子工作站", "思科工作站"],
      pointTypes: ["左上角", "右上角"],
      patientPosition: ["上", "下", "左", "右"],
      patientType: ["单开门", "双开门", "平移门"],
      patientDirection: ["走廊", "检查室"],
      slideDirection: [],
      technicianDirection: ["开向控制室", "开向检查室"],
      technicianType: ["单开门", "双开门"],
      controlDoorType: ["单开门", "双开门"],
      doorPositionType: ["下", "左", "右"],
      doorDirectionType: ["开向控制室", "开向走廊"],
      wiringType: ["电缆沟布线", "明线槽布线"],
      zhuNum: "",
      zhuIndex: 0,
      zhuParamsForm: {
        zhuLocationX: "",
        zhuLocationY: "",
        zhuLength: "",
        zhuWidth: "",
      },
      defaultValue: "",
      checkMark: -1,
      checkedValue: "",
      checkedValue1: "",
      deviceValue: "",
      deviceUiValue: "",
      deviceChoseValue: "",
      index: -1,
      deviceItem: {},
      ZhuZi: false,
      partsPopup: "",
      templateList: [],
      copyDataObj: {},
      optionalRadioIdList: [],
      // jianchachuang: localStorage.getItem('system').indexOf('Symbia') == 0 ? false : true,
      allProduct: [
        "Symbia Evo Excel",
        "Symbia Intevo16",
        "Symbia Intevo 6",
        "Symbia Intevo 2",
        "Symbia Intevo Excel",
        "Symbia Intevo Bold",
        "Mammomat Fusion",
        "MAMMOMAT Inspiration",
        "MAMMOMAT Revelation",
        "SOMATOM go.Now",
        "SOMATOM go.Up",
        "SOMATOM go.All",
        "SOMATOM go.Top",
        "SOMATOM go.Fit",
        "Luminos Fusion（II VE10)",
        "Mammomat Select",
        "Luminos Fusion zhijie(FD VE10)",
        "Luminos Select",
      ],
     
      // 这两个dataObj有一个是有默认数据的,本地调试的时候用有默认数据的,发布版本用不带默认数据的
      // dataObj: {
      //   email: "", //用户登录邮箱
      //   productName: "", //产品名
      //   examinationCouchId: "", //检查床id
      //   examinationCouchName: "", //检查床名字
      //   aircondition: {
      //     //空调
      //   },
      //   airConditionerGroup: [],
      //   powerBox: {
      //     //配电箱
      //     x: 0,
      //     y: 0,
      //     r: 0,
      //   },
      //   dehumidifier: {
      //     //除湿机
      //     x: 0,
      //     y: 0,
      //     r: 0,
      //   },
      //   deviceIdList: [], //配件Id
      //   point: "左上角", // 定位点设置 '0'左上角; '1'右上角
      //   wiring: "电缆沟布线", //布线方式 '0'电缆沟布线; '1'明线槽布线
      //   mainDevice: {
      //     mainDeviceId: "", //主设备id
      //     angle: 0, //旋转角度
      //     modelUrl: "",
      //     point: {
      //       //位置
      //       x: 0,
      //       y: 0,
      //     },
      //   },
      //   // 机房设置数据
      //   machineRoomForm: {
      //     length: 610, // 机房长度
      //     width: 513, //机房宽度
      //     structureHeight: "300", //机房结构高度
      //     ceilingHeight: "280", //机房假天花高度
      //     wallThickness: 24, //墙厚
      //     decorateThickness: 0, //装饰层厚
      //     patientPosition: "上", //病患通道方位 0上;1下; 2左; 3右;
      //     patientLocation: 452, //病患通道位置
      //     patientWidth: 125, //病患通道宽度
      //     patientType: "平移门", //病患通道类型 0单开门; 1双开门; 2平移门
      //     patientDirection: "检查室", //病患通道朝向; 0 走廊 ; 1 检查室
      //     slideDirection: "右", //平移门滑动方向 0上;1下;2左;3右     现改为0:左（下） 1:右（上）
      //     corridorWidth: 0, //走廊宽度
      //     technicianLocation: 300, //技师通道位置
      //     technicianWidth: 125, //技师通道宽度
      //     technicianDirection: "开向检查室", //技师通道朝向;0开向控制室;1开向检查室
      //     technicianType: "单开门", //技师通道类型; 0单开门;1双开门
      //   },
      //   controlRoomForm: {
      //     location: 0, //控制室位置
      //     length: 0, // 控制室长度
      //     width: 0, //控制室宽度
      //     windowLocation: 100, //观察窗位置
      //     windowWidth: 125, //观察窗宽度
      //     doorPosition: "左", //控制室门方位;1下;2左;3右
      //     doorType: "单开门", //控制室门类型 0单开门; 1双开门
      //     doorLocation: 0, //控制室门位置
      //     doorWidth: 0, //控制室门宽度
      //     doorDirection: "开向控制室", //控制室门朝向;0开向走廊;1开向控制室
      //   },
      //   doorsWindows: {
      //     //门窗信息
      //     doorsHeight: 200, //门高度
      //     windowToGround: 100, //窗离地面高度
      //     windowsHeight: 80, //窗自身高度
      //   },
      //   // 柱子数据
      //   zhuForm: [],
      //   paramsAlarmList: [], //参数风险
      //   dragAlarmList: {}, //拖拽页风险
      //   allAlarm: [], //全部风险
      //   tuzhiRotation: 0,
      //   tuzhiScale: 1,
      // },
      dataObj: {
        email:"" , //用户登录邮箱
        productName: "", //产品名
        examinationCouchId: "", //检查床id
        examinationCouchName: "", //检查床名字
        aircondition: {
          //空调
        },
        airConditionerGroup: [],
        powerBox: {
          //配电箱
          x: 0,
          y: 0,
          r: 0,
        },
        dehumidifier: {
          //除湿机
          x: 0,
          y: 0,
          r: 0,
        },
        deviceIdList: [], //配件Id
        point: "左上角", // 定位点设置 '0'左上角; '1'右上角
        wiring: "电缆沟布线", //布线方式 '0'电缆沟布线; '1'明线槽布线
        mainDevice: {
          mainDeviceId: "", //主设备id
          angle: 0, //旋转角度
          modelUrl: "",
          point: {
            //位置
            x: 0,
            y: 0,
          },
        },
        // 机房设置数据
        machineRoomForm: {
          length: "", // 机房长度
          width: "", //机房宽度
          structureHeight: "", //机房结构高度
          ceilingHeight: "", //机房假天花高度
          wallThickness: 24, //墙厚
          decorateThickness: 0, //装饰层厚
          patientPosition: "右", //病患通道方位 0上; 2左; 3右;
          patientLocation: "", //病患通道位置
          patientWidth: "", //病患通道宽度
          patientType: "平移门", //病患通道类型 0单开门; 1双开门; 2平移门
          patientDirection: "检查室", //病患通道朝向; 0 走廊 ; 1 检查室
          slideDirection: "右", //平移门滑动方向 0上;1下;2左;3右
          corridorWidth: 0, //走廊宽度
          technicianLocation: "", //技师通道位置
          technicianWidth: "", //技师通道宽度
          technicianLocation: "", //技师通道位置
          technicianDirection: "开向检查室", //技师通道朝向;0开向控制室;1开向检查室
          technicianType: "单开门", //技师通道类型; 0单开门;1双开门
        },
        controlRoomForm: {
          location: 0, //控制室位置
          length: "", // 控制室长度
          width: "", //控制室宽度
          windowLocation: "", //观察窗位置
          windowWidth: "", //观察窗宽度
          doorPosition: "右", //控制室门方位;1下;2左;3右
          doorType: "单开门", //控制室门类型 0单开门; 1双开门
          doorLocation: "", //控制室门位置
          doorWidth: "", //控制室门宽度
          doorDirection: "开向控制室", //控制室门朝向;0开向走廊;1开向控制室
        },
        doorsWindows: {
          //门窗信息
          doorsHeight: 200, //门高度
          windowToGround: 100, //窗离地面高度
          windowsHeight: 80, //窗自身高度
        },
        // 柱子数据
        zhuForm: [],
        paramsAlarmList: [], //参数风险
        dragAlarmList: {}, //拖拽页风险
        allAlarm: [], //全部风险
        tuzhiRotation: 0,
        tuzhiScale: 1,
      },
      dangerShow: false,
      lengthDangerShow: false,
      widthDangerShow: false,
      ceilingHeightDangerShow: false,
      decorateThicknessDangerShow: false,
      patientWidthDangerShow: false,
      technicianWidthDangerShow: false,
      controlLengthDangerShow: false,
      controlWidthDangerShow: false,
      windowWidthDangerShow: false,
      dangers: {}, //风险提示
      errorObj: {},
      examinationCouchId: "",
      examinationCouchName: "",
      baseUrlApi: window.atob(baseUrlApi),
      regularField: -2,
      isReasonable: true,
      fieldType: "tel",
    };
  },
  activated() {
    this.ThirdPartyProjectId = localStorage.getItem("ThirdPartyProjectId");
    // 查找是否是支持制图的产品,不支持跳转到提醒页面
    if (this.systemName && !this.allProduct.includes(this.systemName)) {
      this.$router.push("/testing");
    }
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    $route(n, o) {
      if (n.name == "2Droom") {
        var that = this;
        setTimeout(() => {
          that.$bus.$emit("change");
        }, 200);
      }
    },
  },

  created() {
    this.$bus.$on("back", (ss) => {
      this.dataObj.deviceIdList = [];
      this.optionalRadioList = [];
      this.optionalSingle();
      this.dataObj.paramsAlarmList = [];
    });
    this.ThirdPartyProjectId = localStorage.getItem("ThirdPartyProjectId");
    // if (this.$route.query.id) {
    //   this.ThirdPartyProjectId = this.$route.query.id;
    // } else {
    //   this.ThirdPartyProjectId = "48304413-727a-43db-adc4-0c25ab852cb1"; //gonow
    //   // this.ThirdPartyProjectId = "afbc0b3e-cf13-4ecd-b00d-a3f3f1de5a28";
    // }
    // localStorage.setItem("ThirdPartyProjectId", this.ThirdPartyProjectId);
    // 初始化时清空柱子参数,风险数据
    this.dataObj.zhuForm = [];
    this.dataObj.paramsAlarmList = [];
    this.userLogin();
    this.$nextTick(() => {
      this.fieldInit();
    });
  },
  methods: {
    // refreshSaveData() {
    //   if (sessionStorage.getItem("homeData")) {
    //     this.dataObj = JSON.parse(sessionStorage.getItem("homeData"));
    //   }
    //   // 控制室开关
    //   if (sessionStorage.getItem("isHaveKong")) {
    //     if (sessionStorage.getItem("isHaveKong") == "true") {
    //       this.kongChecked = true;
    //     } else {
    //       this.kongChecked = false;
    //     }
    //     this.switchChangeKong(this.kongChecked);
    //   }
    // },
    ...mapMutations(["changeLogin"]),
    // 用户登录
    userLogin() {
      console.log(myMSALObj, 'myMSALObj')
      console.log(myMSALObj.getAllAccounts(), 'myMSALObj.getAllAccounts()')
      let _this = this;
      let email = "dezhong.li.ext@siemens-healthineers.com"; //微软账号
      // let email = myMSALObj.getAllAccounts()[0].username; //微软返回的账号
      Auth({
        Account: email,
        signature: AES.encrypt(email).toString(),
      }).then((res) => {
        if (res.data.success) {
          _this.userToken = "Bearer " + res.data.data.token;
          // let token = Base64.encode(_this.userToken);
          let token = AES.encrypt(_this.userToken).toString();
          sessionStorage.setItem("Authorization", token);
          // sessionStorage.setItem("Authorization", _this.userToken )
          // console,log(Base64.decode(_this.userToken), '----------------')
          _this.changeLogin({ Authorization: _this.userToken });
          this.getProjectInfo();
          _this.dataObj.email = email; //用户邮箱传给后端
        } else {
          Dialog.alert({
            message: res.data.message,
          }).then(() => {
            //用户不符则返回pmpower
            this.$router.push({ name: "Testing" });
          });
        }
      });
    },
    //  // 测试Symbia Intevo Bold
    // getProjectInfo() {
    //   this.$set(this.dataObj, "ThirdPartyProject", {
    //     shareOliNo: "1-HUIF4J",
    //     shipToPartyCnName: "中山大学肿瘤防治中心（中山大学附属",
    //     shipToPartyEnName: "Sun-Yat Sen University Cancer",
    //     gg: "MI",
    //     system: "Symbia Intevo Bold",
    //     pmgId: "Z00334PP",
    //     pmEmail: "zhenting.zhang@siemens-healthineers.com",
    //     pmName: "Zhang ZhenTing",
    //     subRegionHeaderList:   [
    //         {
    //             "gId": "Z000MX4B",
    //             "name": "Rao HuiYun",
    //             "email": "huiyun.rao@siemens-healthineers.com"
    //         },
    //         {
    //             "gId": "Z002WJ3R",
    //             "name": "Hu XueMing",
    //             "email": "xueming.hu@siemens-healthineers.com"
    //         },
    //     ],
    //   });
    //   this.$set(
    //     this.dataObj,
    //     "ThirdPartyProjectId",
    //     "2f9d6b6c-0dba-41b7-a3fd-734d80fe109a"
    //   );
    //   this.$set(this.dataObj, "systemName", "Symbia Intevo Bold");
    //   this.systemName = "Symbia Intevo Bold";
    //   this.dataObj.productName = "Symbia Intevo Bold";
    //   this.$bus.$emit(
    //     "headValue",
    //     "巫山县人民医院（巫山县精神",
    //     "Symbia Intevo Bold"
    //   );
    //   localStorage.setItem("system", "Symbia Intevo Bold");
    //   this.getDeviceType();
    //   this.getModelTipLimitByName();
    //   this.getModelLimitByName();
    // },
    // // 测试Mammomat Select
    // getProjectInfo() {
    //   this.$set(this.dataObj, "ThirdPartyProject", {
    //     shareOliNo: "2419521",
    //     shipToPartyCnName: "巫山县人民医院（巫山县精神",
    //     shipToPartyEnName: "Wushan County People's Hospital",
    //     gg: "SSME XP",
    //     system: "Mammomat Select",
    //     pmgId: "Z003M75Y",
    //     pmEmail: "jiangwei@siemens-healthineers.com",
    //     pmName: "Jiang Wei",
    //     subRegionHeaderList:   [
    //         {
    //             "gId": "Z001U92T",
    //             "name": "Gan GuoSheng",
    //             "email": "guosheng.gan@siemens-healthineers.com"
    //         },
    //         {
    //             "gId": "Z0034PVN",
    //             "name": "Shen Lin",
    //             "email": "shen.lin@siemens-healthineers.com"
    //         },
    //         {
    //             "gId": "Z002W5UH",
    //             "name": "Gao Bo",
    //             "email": "gao.bo@siemens-healthineers.com"
    //         }
    //     ],
    //   });
    //   this.$set(
    //     this.dataObj,
    //     "ThirdPartyProjectId",
    //     "b549785a-e0fc-46de-b88e-66bd8edd2955"
    //   );
    //   this.$set(this.dataObj, "systemName", "Mammomat Select");
    //   this.systemName = "Mammomat Select";
    //   this.dataObj.productName = "Mammomat Select";
    //   this.$bus.$emit(
    //     "headValue",
    //     "巫山县人民医院（巫山县精神",
    //     "Mammomat Select"
    //   );
    //   localStorage.setItem("system", "Mammomat Select");
    //   this.getDeviceType();
    //   this.getModelTipLimitByName();
    //   this.getModelLimitByName();
    // },
    // 测试Mammomat Fusion
    getProjectInfoMammomatFusion() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "CNA-68045",
        shipToPartyCnName: "泰州妇产医院有限公司",
        shipToPartyEnName: "Taizhou Maternity Hospital Co. Ltd.",
        gg: "XP",
        system: "Mammomat Fusion",
        pmgId: "Z0016D8K",
        pmEmail: "hao.yin@siemens-healthineers.com",
        pmName: "Yin Hao",
        subRegionHeaderList:   [
            {
                "gId": "Z000KD9K",
                "name": "Liu ChangMin",
                "email": "changmin.liu@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "35bc5e39-fe33-4692-aac3-6eb9356cf69e"
      );
      this.$set(this.dataObj, "systemName", "Mammomat Fusion");
      this.systemName = "Mammomat Fusion";
      this.dataObj.productName = "Mammomat Fusion";
      this.$bus.$emit(
        "headValue",
        "泰州妇产医院有限公司",
        "Mammomat Fusion"
      );
      localStorage.setItem("system", "Mammomat Fusion");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
    //  // 测试MAMMOMAT Inspiration
    // getProjectInfo() {
    //   this.$set(this.dataObj, "ThirdPartyProject", {
    //     shareOliNo: "2457113",
    //     shipToPartyCnName: "仪征市妇幼保健院",
    //     shipToPartyEnName: "Yizheng city Maternal and Child",
    //     gg: "XP",
    //     system: "MAMMOMAT Inspiration",
    //     pmgId: "Z003PACJ",
    //     pmEmail: "zhao.jz@siemens-healthineers.com",
    //     pmName: "Zhao Jie",
    //     subRegionHeaderList:   [
    //         {
    //             "gId": "Z000KD9K",
    //             "name": "Liu ChangMin",
    //             "email": "changmin.liu@siemens-healthineers.com"
    //         }
    //     ],
    //   });
    //   this.$set(
    //     this.dataObj,
    //     "ThirdPartyProjectId",
    //     "83959f02-79f5-4b18-86be-df42e1d62beb"
    //   );
    //   this.$set(this.dataObj, "systemName", "MAMMOMAT Inspiration");
    //   this.systemName = "MAMMOMAT Inspiration";
    //   this.dataObj.productName = "MAMMOMAT Inspiration";
    //   this.$bus.$emit(
    //     "headValue",
    //     "仪征市妇幼保健院",
    //     "MAMMOMAT Inspiration"
    //   );
    //   localStorage.setItem("system", "MAMMOMAT Inspiration");
    //   this.getDeviceType();
    //   this.getModelTipLimitByName();
    //   this.getModelLimitByName();
    // },
     // 测试MAMMOMAT Revelation
    getProjectInfoMAMMOMATRevelation() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "1-HODQK3",
        shipToPartyCnName: "云南大学附属医院(云南省第二人民医院、云南省眼科医院)",
        shipToPartyEnName: "Affiliated Hospital of Yunnan",
        gg: "XP",
        system: "MAMMOMAT Revelation",
        pmgId: "Z0033A1H",
        pmEmail: "zhiqiang.zhang@siemens-healthineers.com",
        pmName: "Zhang ZhiQiang",
        subRegionHeaderList:   [
            {
                "gId": "Z0047WUR",
                "name": "Youjun Tang",
                "email": "youjun.tang.ext@siemens-healthineers.com"
            },
            {
                "gId": "Z000DF0H",
                "name": "Zheng Lei",
                "email": "lei.zheng@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "b1e76716-bbe9-40ab-9c7b-670c3116b5ef"
      );
      this.$set(this.dataObj, "systemName", "MAMMOMAT Revelation");
      this.systemName = "MAMMOMAT Revelation";
      this.dataObj.productName = "MAMMOMAT Revelation";
      this.$bus.$emit(
        "headValue",
        "云南大学附属医院(云南省第二人民医院、云南省眼科医院)",
        "MAMMOMAT Revelation"
      );
      localStorage.setItem("system", "MAMMOMAT Revelation");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
    //  测试Luminos Select
    getProjectInfoLuminosSelect() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "",
        shipToPartyCnName: "息县妇幼保健院",
        shipToPartyEnName: "Xi County Maternity and Children",
        gg: "SSME XP",
        system: "Luminos Select",
        pmgId: "",
        pmEmail: "",
        pmName: "Zhao Yang_N",
        subRegionHeaderList:   [
            // {
            //     "gId": "Z000KD9K",
            //     "name": "Liu ChangMin",
            //     "email": "changmin.liu@siemens-healthineers.com"
            // }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "942ccd17-9817-45ce-abfc-f17acf567f00"
      );
      this.$set(this.dataObj, "systemName", "Luminos Select");
      this.systemName = "Luminos Select";
      this.dataObj.productName = "Luminos Select";
      this.$bus.$emit(
        "headValue",
        "息县妇幼保健院",
        "Luminos Select"
      );
      localStorage.setItem("system", "Luminos Select");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
     // 测试Luminos Fusion（II VE10)
    getProjectInfoLuminosFusionIIVE10() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "1-HS73H7",
        shipToPartyCnName: "临泉华源健康管理有限公司",
        shipToPartyEnName: "Linquan Huayuan Health Management",
        gg: "SSME XP",
        system: "Luminos Fusion（II VE10)",
        pmgId: "Z0040FNN",
        pmEmail: "nan.wang@siemens-healthineers.com",
        pmName: "Wang Nan",
        subRegionHeaderList:   [
            {
                "gId": "Z000KD9K",
                "name": "Liu ChangMin",
                "email": "changmin.liu@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "f6f092e0-15b5-4192-b19a-e9b7ff934a84"
      );
      this.$set(this.dataObj, "systemName", "Luminos Fusion（II VE10)");
      this.systemName = "Luminos Fusion（II VE10)";
      this.dataObj.productName = "Luminos Fusion（II VE10)";
      this.$bus.$emit(
        "headValue",
        "临泉华源健康管理有限公司",
        "Luminos Fusion（II VE10)"
      );
      localStorage.setItem("system", "Luminos Fusion（II VE10)");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
    // 测试Symbia Intevo Excel
    getProjectInfoSymbiaIntevoExcel() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "1-HWW0Z7",
        shipToPartyCnName: "柳州市工人医院",
        shipToPartyEnName: "Liuzhou Worker's Hospital",
        gg: "MI",
        system: "Symbia Intevo Excel",
        pmgId: "Z0037FMK",
        pmEmail: "guihang.peng@siemens-healthineers.com",
        pmName: "Peng GuiHang",
        subRegionHeaderList:   [
            {
                "gId": "Z002N50S",
                "name": "Tan ZhiGuang",
                "email": "zhiguang.tan@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "ba709d5c-3932-494a-8c6a-e15753f129aa"
      );
      this.$set(this.dataObj, "systemName", "Symbia Intevo Excel");
      this.systemName = "Symbia Intevo Excel";
      this.dataObj.productName = "Symbia Intevo Excel";
      this.$bus.$emit(
        "headValue",
        "柳州市工人医院",
        "Symbia Intevo Excel"
      );
      localStorage.setItem("system", "Symbia Intevo Excel");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
    // // 测试Symbia Intevo 2
    // getProjectInfo() {
    //   this.$set(this.dataObj, "ThirdPartyProject", {
    //     shareOliNo: "1-HM6YPN",
    //     shipToPartyCnName: "太仓市第一人民医院",
    //     shipToPartyEnName: "Taicang No.1 People's Hospital",
    //     gg: "MI",
    //     system: "Symbia Intevo 2",
    //     pmgId: "Z002CV9W",
    //     pmEmail: "jianhe.ren@siemens-healthineers.com",
    //     pmName: "Ren JianHe",
    //     subRegionHeaderList:   [
    //         {
    //             "gId": "Z000JPBK",
    //             "name": "Xu YiPing",
    //             "email": "yiping.xu@siemens-healthineers.com"
    //         }
    //     ],
    //   });
    //   this.$set(
    //     this.dataObj,
    //     "ThirdPartyProjectId",
    //     "f5f2e9d3-5ab8-4780-8eea-1fe0f9676f02"
    //   );
    //   this.$set(this.dataObj, "systemName", "Symbia Intevo 2");
    //   this.systemName = "Symbia Intevo 2";
    //   this.dataObj.productName = "Symbia Intevo 2";
    //   this.$bus.$emit(
    //     "headValue",
    //     "太仓市第一人民医院",
    //     "Symbia Intevo 2"
    //   );
    //   localStorage.setItem("system", "Symbia Intevo 2");
    //   this.getDeviceType();
    //   this.getModelTipLimitByName();
    //   this.getModelLimitByName();
    // },
    // 测试Symbia Evo Excel
    getProjectInfoSymbiaEvoExcel() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "2048109",
        shipToPartyCnName: "亳州宝璋医院有限公司",
        shipToPartyEnName: "Bozhou Baozhang Hospital Co. Ltd.",
        gg: "MI",
        system: "Symbia Evo Excel",
        pmgId: "Z0040FNN",
        pmEmail: "nan.wang@siemens-healthineers.com",
        pmName: "Wang Nan",
        subRegionHeaderList:   [
            {
                "gId": "Z000KD9K",
                "name": "Liu ChangMin",
                "email": "changmin.liu@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "afbc0b3e-cf13-4ecd-b00d-a3f3f1de5a28"
      );
      this.$set(this.dataObj, "systemName", "Symbia Evo Excel");
      this.systemName = "Symbia Evo Excel";
      this.dataObj.productName = "Symbia Evo Excel";
      this.$bus.$emit(
        "headValue",
        "亳州宝璋医院有限公司",
        "Symbia Evo Excel"
      );
      localStorage.setItem("system", "Symbia Evo Excel");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
     // 测试Symbia Intevo 6
    getProjectInfoSymbiaIntevo6() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "1-7T3E86",
        shipToPartyCnName: "中国人民解放军空军军医大学第一附属",
        shipToPartyEnName: "Air Force Military Medical",
        gg: "MI",
        system: "Symbia Intevo 6",
        pmgId: "Z003CJEY",
        pmEmail: "yuepeng.chen@siemens-healthineers.com",
        pmName: "Chen YuePeng",
        subRegionHeaderList:   [
            {
                "gId": "Z0034PVN",
                "name": "Shen Lin",
                "email": "shen.lin@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "d61038b9-badd-49df-922c-d1807a90fe01"
      );
      this.$set(this.dataObj, "systemName", "Symbia Intevo 6");
      this.systemName = "Symbia Intevo 6";
      this.dataObj.productName = "Symbia Intevo 6";
      this.$bus.$emit(
        "headValue",
        "中国人民解放军空军军医大学第一附属",
        "Symbia Intevo 6"
      );
      localStorage.setItem("system", "Symbia Intevo 6");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
    //  // 测试Luminos Fusion zhijie(FD VE10)
    // getProjectInfo() {
    //   this.$set(this.dataObj, "ThirdPartyProject", {
    //     shareOliNo: "1-HS73H7",
    //     shipToPartyCnName: "临泉华源健康管理有限公司",
    //     shipToPartyEnName: "Linquan Huayuan Health Management",
    //     gg: "SSME XP",
    //     system: "Luminos Fusion zhijie(FD VE10)",
    //     pmgId: "Z0040FNN",
    //     pmEmail: "nan.wang@siemens-healthineers.com",
    //     pmName: "Wang Nan",
    //     subRegionHeaderList:   [
    //         {
    //             "gId": "Z000KD9K",
    //             "name": "Liu ChangMin",
    //             "email": "changmin.liu@siemens-healthineers.com"
    //         }
    //     ],
    //   });
    //   this.$set(
    //     this.dataObj,
    //     "ThirdPartyProjectId",
    //     "f6f092e0-15b5-4192-b19a-e9b7ff934a84"
    //   );
    //   this.$set(this.dataObj, "systemName", "Luminos Fusion zhijie(FD VE10)");
    //   this.systemName = "Luminos Fusion zhijie(FD VE10)";
    //   this.dataObj.productName = "Luminos Fusion zhijie(FD VE10)";
    //   this.$bus.$emit(
    //     "headValue",
    //     "临泉华源健康管理有限公司",
    //     "Luminos Fusion zhijie(FD VE10)"
    //   );
    //   localStorage.setItem("system", "Luminos Fusion zhijie(FD VE10)");
    //   this.getDeviceType();
    //   this.getModelTipLimitByName();
    //   this.getModelLimitByName();
    // },
     // 测试go.Fit
    getProjectInfogoFit() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "2709725",
        shipToPartyCnName: "上海博爱医院有限公司",
        shipToPartyEnName: "Shanghai Bo'ai Hospital Co. Ltd.",
        gg: "SSME CT",
        system: "SOMATOM go.Fit",
        pmgId: "Z002CV9W",
        pmEmail: "jianhe.ren@siemens-healthineers.com",
        pmName: "Ren JianHe",
        subRegionHeaderList:   [
            {
                "gId": "Z000JPBK",
                "name": "Xu YiPing",
                "email": "yiping.xu@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "014d3a20-93f5-4a99-8012-ce325f519ace"
      );
      this.$set(this.dataObj, "systemName", "SOMATOM go.Fit");
      this.systemName = "SOMATOM go.Fit";
      this.dataObj.productName = "SOMATOM go.Fit";
      this.$bus.$emit(
        "headValue",
        "上海博爱医院有限公司",
        "SOMATOM go.Fit"
      );
      localStorage.setItem("system", "SOMATOM go.Fit");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
    // 测试go.up
    getProjectInfogoup() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "2097466",
        shipToPartyCnName: "彭州市中医医院",
        shipToPartyEnName: "Pengzhou TCM Hospital",
        gg: "SSME CT",
        system: "SOMATOM go.Up",
        pmgId: "Z002W5UH",
        pmEmail: "gao.bo@siemens-healthineers.com",
        pmName: "Gao Bo",
        subRegionHeaderList:  [
            {
                "gId": "Z001U7WU",
                "name": "Jiang YiHai",
                "email": "yihai.jiang@siemens-healthineers.com"
            },
            {
                "gId": "Z0033A1H",
                "name": "Zhang ZhiQiang",
                "email": "zhiqiang.zhang@siemens-healthineers.com"
            }
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "42a8c9c9-f538-4a10-8a36-69498789cfab"
      );
      this.$set(this.dataObj, "systemName", "SOMATOM go.Up");
      this.systemName = "SOMATOM go.Up";
      this.dataObj.productName = "SOMATOM go.Up";
      this.$bus.$emit(
        "headValue",
        "彭州市中医医院",
        "SOMATOM go.Up"
      );
      localStorage.setItem("system", "SOMATOM go.Up");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
     // 测试go.all
    getProjectInfogoAll() {
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "1-HU8ILJ",
        shipToPartyCnName: "杭州市拱墅区祥符街道社区卫生服务中",
        shipToPartyEnName: "Hangzhou City Gongshu District",
        gg: "SSME CT",
        system: "SOMATOM go.All",
        pmgId: "Z0045D4E",
        pmEmail: "cheng.zong@siemens-healthineers.com",
        pmName: "Zong Cheng",
        subRegionHeaderList: [
            {
                "gId": "Z002CWNN",
                "name": "Li HongTao",
                "email": "hongtao.li@siemens-healthineers.com"
            },
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "41AB07B7-CB8D-40BB-9FB7-21C73932E877"
      );
      this.$set(this.dataObj, "systemName", "SOMATOM go.All");
      this.systemName = "SOMATOM go.All";
      this.dataObj.productName = "SOMATOM go.All";
      this.$bus.$emit(
        "headValue",
        "杭州市拱墅区祥符街道社区卫生服务中",
        "SOMATOM go.All"
      );
      localStorage.setItem("system", "SOMATOM go.All");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },
    //   // 测试go.Top
    // getProjectInfo() {
    //   this.$set(this.dataObj, "ThirdPartyProject", {
    //     shareOliNo: "2865017",
    //     shipToPartyCnName: "上海昕诺医学研究有限公司",
    //     shipToPartyEnName: "Shanghai Xinnuo Medical Research",
    //     gg: "SSME CT",
    //     system: "SOMATOM go.Top",
    //     pmgId: "Z0043XYV",
    //     pmEmail: "yifan.xu@siemens-healthineers.com",
    //     pmName: "Xu YiFan",
    //     subRegionHeaderList: [
    //         {
    //             "gId": "Z000JPBK",
    //             "name": "Xu YiPing",
    //             "email": "yiping.xu@siemens-healthineers.com"
    //         },
    //     ],
    //   });
    //   this.$set(
    //     this.dataObj,
    //     "ThirdPartyProjectId",
    //     "898f157e-7a30-4582-aab5-8f601109cbc3"
    //   );
    //   this.$set(this.dataObj, "systemName", "SOMATOM go.Top");
    //   this.systemName = "SOMATOM go.Top";
    //   this.dataObj.productName = "SOMATOM go.Top";
    //   this.$bus.$emit(
    //     "headValue",
    //     "上海昕诺医学研究有限公司",
    //     "SOMATOM go.Top"
    //   );
    //   localStorage.setItem("system", "SOMATOM go.Top");
    //   this.getDeviceType();
    //   this.getModelTipLimitByName();
    //   this.getModelLimitByName();
    // },
    // 测试数据 go.Now
    getProjectInfogoNow() {
      console.log("-----------");
      this.$set(this.dataObj, "ThirdPartyProject", {
        shareOliNo: "CNA-66751",
        shipToPartyCnName: "烟台市昆仑山医院有限公司",
        shipToPartyEnName: "Yantai Kunlunshan Hospital Co. Ltd.",
        gg: "SSME CT",
        system: "SOMATOM go.Now",
        pmgId: "Z0015RKD",
        pmEmail: "yuqiang.cui@siemens-healthineers.com",
        pmName: "Cui YuQiang",
        subRegionHeaderList: [
          {
            gId: "Z000JPBK",
            name: "Xu YiPing",
            email: "yiping.xu@siemens-healthineers.com",
          },
          {
            gId: "Z002CWNN",
            name: "Li HongTao",
            email: "hongtao.li@siemens-healthineers.com",
          },
          {
            gId: "Z000KD9K",
            name: "Liu ChangMin",
            email: "changmin.liu@siemens-healthineers.com",
          },
          {
            gId: "Z001SDAM",
            name: "Wang TingQing",
            email: "tingqing.wang@siemens-healthineers.com",
          },
        ],
      });
      this.$set(
        this.dataObj,
        "ThirdPartyProjectId",
        "48304413-727a-43db-adc4-0c25ab852cb1"
      );
      this.$set(this.dataObj, "systemName", "SOMATOM go.Now");
      this.systemName = "SOMATOM go.Now";
      this.dataObj.productName = "SOMATOM go.Now";
      this.$bus.$emit(
        "headValue",
        "烟台市昆仑山医院有限公司",
        "SOMATOM go.Now"
      );
      localStorage.setItem("system", "SOMATOM go.Now");
      this.getDeviceType();
      this.getModelTipLimitByName();
      this.getModelLimitByName();
    },

    //  getProjectInfo(){
    //   console.log(this.ThirdPartyProjectId, 'this.ThirdPartyProjectId')
    //   if(this.ThirdPartyProjectId === 'f5ea1e24-c8a4-4b91-8c7d-641571e52608'){
    //     this.getProjectInfogoup()
    //   }else if(this.ThirdPartyProjectId === 'f26de1cd-9044-4daf-9b92-747bc449e390'){
    //     this.getProjectInfoLuminosSelect()
    //   }else if(this.ThirdPartyProjectId === 'c0a66a33-470c-4b5f-9906-f8c6830bb438'){
    //     this.getProjectInfoMammomatFusion()
    //   }else if(this.ThirdPartyProjectId === 'd5e7de12-2313-4255-ac69-55e4e8376eb1'){
    //     this.getProjectInfoSymbiaEvoExcel()
    //   }else if(this.ThirdPartyProjectId === '56002af4-2845-41e9-b5dc-004b858f0b9e'){
    //     this.getProjectInfoSymbiaIntevo6()
    //   }else if(this.ThirdPartyProjectId === '113eb5b5-64a9-44fa-af95-ad18e6ea87d4'){
    //     this.getProjectInfoMAMMOMATRevelation()
    //   }else if(this.ThirdPartyProjectId === 'de700be0-43d9-4d1e-b392-008b6fc27463'){
    //     this.getProjectInfogoAll()
    //   }else if(this.ThirdPartyProjectId === '3cab1777-fa95-4cf8-9fab-63e13265a0c5'){
    //     this.getProjectInfogoFit()
    //   }else if(this.ThirdPartyProjectId === 'daa7efb9-3fb7-4a25-81c2-0a202b1e78a7'){
    //     this.getProjectInfoLuminosFusionIIVE10()
    //   }else if(this.ThirdPartyProjectId === 'b62371df-7b12-4bc5-bdb3-694b5853a76d'){
    //     this.getProjectInfoSymbiaIntevoExcel()
    //   }else if(this.ThirdPartyProjectId === '48eb538f-c10d-4f02-8031-94e2e6572efa'){
    //     this.getProjectInfogoNow()
    //   }
    //  },

    // 获取项目详情
    getProjectInfo() {
      GetProjectInfo({ id: this.ThirdPartyProjectId }).then((res) => {
        console.log(JSON.stringify(res.data.data));
        if (res.data.isSuccess) {
          if(!this.allProduct.includes(res.data.data.system )){
            this.$router.push('/testing')
          }
          this.$set(this.dataObj, 'ThirdPartyProject', res.data.data)
          this.$set(this.dataObj, 'ThirdPartyProjectId', this.ThirdPartyProjectId )
          this.$set(this.dataObj, 'systemName', res.data.data.system)
          this.systemName = res.data.data.system
          this.dataObj.productName = res.data.data.system
          if(res.data.data.shipToPartyCnName && res.data.data.shipToPartyCnName!==''){
            this.$bus.$emit('headValue',res.data.data.shipToPartyCnName, res.data.data.system )
          }else{
            this.$bus.$emit('headValue',res.data.data.shipToPartyEnName, res.data.data.system )
          }
          localStorage.setItem('system', res.data.data.system)
          this.getDeviceType()
          this.getModelTipLimitByName()
          this.getModelLimitByName()
        } else {
          Dialog.alert({
            // message: res.data.msg
            message: '获取不到项目信息'
          }).then(() => {
            this.$router.push({ name: "Home" });
          })
        }
      })
    },
    // 风险提示
    getModelTipLimitByName() {
      GetModelTipLimitByName({ name: this.dataObj.productName }).then((res) => {
        console.log("风险", res);
        let messageList = [];
        this.dangers = res.data.data;
        for (let i in this.dangers) {
          if (i.indexOf("Tips") != -1) {
            messageList.push(this.dangers[i]);
          }
        }
        messageList.push('机架到控制台线长6.5米，请注意线长问题')
        messageList.push('电缆沟横穿通道,需做好对电缆的保护，防止诊断床等经过时压坏电缆')
        // messageList.push('由于通道门限制，医生行进至检查室路径较远')
        messageList.forEach((item) => {
          let obj = {
            point: {
              x: 0,
              y: 0,
            },
            message: item,
          };
          this.dataObj.allAlarm.push(obj);
        });
      });
    },
    // 获取极限值
    getModelLimitByName() {
      GetModelLimitByName({ name: this.systemName }).then((res) => {
        this.errorObj = res.data.data;
      });
    },
    // 获取配件数据
    getDeviceType() {
      GetDeviceTypeByNameWithApp({ name: this.systemName }).then((res) => {
        this.deviceSeriesResult = res.data.data;
        GetDevicesByName({ name: this.systemName }).then((ress) => {
          this.deviceTypeResult = ress.data.data;
          sessionStorage.setItem('setDeviceTypeResult', JSON.stringify(this.deviceTypeResult))
          this.deviceTypeResult.forEach((item) => {
            if (item.deviceType == 1 || item.deviceType == 0) {
              console.log(item, "有检查床-----");
              this.dataObj.mainDevice.modelUrl = `${this.baseUrlApi}File/Fbx/${
                JSON.parse(item.extJson).LayoutFiles.Web3D
              }/device.glb`;
            }
          });
          console.log("配件猪", this.deviceSeriesResult);
          console.log("配件分", this.deviceTypeResult);
          this.optionalSingle();
          this.multiple();
          console.log("配件id", this.dataObj.deviceIdList);
        });
      });
    },

    optionalSingle() {
      // 左右手位检查床
      for (let k in this.deviceTypeResult) {
        if(localStorage.getItem('system').indexOf('Symbia') !== 0){
          if (
          (this.deviceTypeResult[k].deviceType == 1 &&
            this.deviceTypeResult[k].name == "左手位") ||
          this.deviceTypeResult[k].name == "右手位"
          ) {
            this.examinationCouchName = this.deviceTypeResult[k].name;
            this.dataObj.deviceIdList.push(this.deviceTypeResult[k].id);
          }
        }else{
          if(this.deviceTypeResult[k].deviceType == 1){
            this.examinationCouchName = this.deviceTypeResult[k].name;
            this.dataObj.deviceIdList.push(this.deviceTypeResult[k].id);
          }
        }
        // if (
        //   (this.deviceTypeResult[k].deviceType == 1 &&
        //     this.deviceTypeResult[k].name == "左手位") ||
        //   this.deviceTypeResult[k].name == "右手位"
        // ) {
        //   this.examinationCouchName = this.deviceTypeResult[k].name;
        //   this.dataObj.deviceIdList.push(this.deviceTypeResult[k].id);
        // }
      }
      for (let i in this.deviceSeriesResult) {
        var devices = this.deviceTypeResult.filter((x) => {
          return x.deviceType == i;
        });
        // console.log("配件配件配件配件配件配件", this.deviceSeriesResult[i] + "count=" + devices.length);
        if (devices.length == 0) {
          //没有上传配件
        } else if (devices.length == 1) {
          if (devices[0].isSingle) {
            //必选的单个 不用显示 默认选中
            // console.log("必选的单个 不用显示 默认选中");
            devices.forEach((ele) => {
              // 检查床是必选单个时获取主设备id
                if (ele.deviceType == 1 || ele.deviceType == 0) {
                  console.log('检查床是必选单个时获取主设备id')
                  this.examinationCouchId = ele.id;
                  this.examinationCouchName = ele.name;
                }
                if (!this.dataObj.deviceIdList.includes(ele.id)) {
                  this.dataObj.deviceIdList.push(ele.id);
                }
              
            });
          } else {
            //可选单个 显示
            // console.log("可选单个 显示");
            this.optionalRadioList.push(...devices);
          }
        } else {
          console.log(devices);
          if (devices[0].isSingle) {
            //必选的多个 必须选中一个////////
            // console.log("必选的多个 必须选中一个");
          } else {
            //可选多选 添加一个无的选中
            // console.log("可选 添加一个无的选中");
          }
        }
      }
    },
    // 多选一
    multiple() {
      var result = [];
      for (let i in this.deviceSeriesResult) {
        this.deviceTypeResult.forEach((ele) => {
          if (i == ele.deviceType && ele.isSingle == false) {
            result.push({
              seriesName: this.deviceSeriesResult[i],
              ...ele,
            });
          }
          if (i == ele.deviceType && ele.isSingle == true) {
            result.push({
              seriesName: this.deviceSeriesResult[i],
              ...ele,
            });
          }
        });
      }
      let newData = {};
      result.forEach((e) => {
        //新建属性名
        if (Object.keys(newData).indexOf("" + e.deviceType) === -1) {
          newData[e.deviceType] = [];
        }
        //对应插入属性值
        newData[e.deviceType].push(e);
      });
      for (let i in newData) {
        if (newData[i].length > 1 && newData[i][0]) {
          console.log(newData[i]);
          if (newData[i][0].isSingle == false) {
            newData[i].unshift({ name: "无" });
          }
          // this.$nextTick(() => {
          this.requiredMultipleList.push({
            deviceUiValue: newData[i][0].name == "无" ? "无" : "请选择",
            list: newData[i],
            default: newData[i][1].name,
            series: newData[i][1].seriesName,
            deviceId: "",
          });
          console.log(
            this.requiredMultipleList,
            "this.requiredMultipleList.77777777777"
          );
          // })
        }
      }
    },
    // 选择配件系列
    chooseParts(t, item, index) {
      this.checkMark = -1;
      // // 触发选择配件事件就删除默认的配件id
      Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      this.dataObj.deviceIdList.remove(item.list[1].id);
      this.$refs.devicePopupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      this.partsPopup = t;
      this.index = index;
    },
    // 选择配件选项
    choseDeviceType(item, index, ele) {
      console.log(ele, "ele-----");
      // this.deviceTypeResult.forEach(item => {
      //   if(item.deviceType == 1){
      //     console.log('有检查床-----')
      //     this.dataObj.mainDevice.modelUrl = `${this.baseUrlApi}File/Fbx/${
      //     JSON.parse(ele.extJson).LayoutFiles.Web3D}/device.glb`
      //     this.examinationCouchId = ele.id
      //     this.examinationCouchName = ele.name
      //   }
      // })
      if (item.list[0].deviceType && item.list[0].deviceType == 1) {
        console.log(JSON.parse(ele.extJson).LayoutFiles.Web3D);
        this.dataObj.mainDevice.modelUrl = `${this.baseUrlApi}File/Fbx/${
          JSON.parse(ele.extJson).LayoutFiles.Web3D
        }/device.glb`;
        // axios.get( `${this.baseUrlApi}File/Fbx/${
        //   JSON.parse(ele.extJson).LayoutFiles.Web3D}/device.glb`, {
        //   headers: {
        //     authorization: this.userToken,
        //   },
        // }).then(res=>{
        //   let blob = new Blob([res.data], { type: "octet-stream" });
        //     var Fr = new FileReader();
        //     Fr.readAsDataURL(blob);
        //     Fr.onload = (event) => {
        //      this.dataObj.mainDevice.modelUrl= event.target.result;
        //     };
        // });
        this.examinationCouchId = ele.id;
        this.examinationCouchName = ele.name;
      }
      // console.log('Symbia Evo Excel 系列接口错误无位置')
      this.deviceItem = ele;
      this.checkMark = index;
      this.checkedValue = item;
      if (ele.id) {
        this.deviceValue = ele.id;
      }
      if (ele.name == "无") {
        this.deviceValue = "";
      }
      for (let i in this.requiredMultipleList) {
        if (i == this.index) {
          this.deviceChoseValue = ele.name;
        }
      }
    },

    // 配件弹框确定
    devicePopupConfirm() {
      if (this.deviceChoseValue) {
        this.$refs.devicePopupRef.closePopup();
      }
      this.checkMark = -1;
      for (let i in this.requiredMultipleList) {
        if (i == this.index && this.checkedValue) {
          console.log(this.requiredMultipleList[i]);
          this.requiredMultipleList[i].deviceId = this.deviceValue;
          this.requiredMultipleList[i].deviceUiValue = this.deviceChoseValue;
        }
      }
      this.deviceChoseValue = "";
      this.checkedValue = "";
    },
    // 机房输入框获取焦点
    focusEvent(n) {
      this.$refs.roomFieldRef.openPopup();
      this.fieldName = n;
      this.popupTitle = n;
      this.ruleShow = false;
      this.fieldValue = "";
      console.log(this.fieldName);
      console.log(n);
      this.$nextTick(() => {
        this.$refs.focusRef.focus();
      });
    },
    // 房间弹框输入框获取焦点以后清空上次的报错内容
    paramsPopupFocus(e) {
      //初始化
      this.ruleShow = false;
      // var isAndroid = navigator.userAgent.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIos) {
        // 相关逻辑代码
        this.fieldName == "控制室位置"
          ? (this.fieldType = "")
          : (this.fieldType = "tel");
        this.$refs.roomFieldRef.$children[0].$el.style.top = 40 + "%";
      } else {
        this.$refs.roomFieldRef.$children[0].$el.style.top = 50 + "%";
      }
    },
    enterEvent() {
      this.roomFieldConfirm();
    },
    // 四舍五入取整
    changeNumber() {
      let str = "" + this.fieldValue;
      if (str.indexOf(".") != -1) {
        this.fieldValue = Number(str).toFixed(0);
        console.log(this.fieldValue);
      }
    },
    // 房间各参数校验
    roomFieldConfirm() {
      console.log('55555555555555')
      // this.fieldValue
      //   ? (this.regularField = this.fieldValue.search(/[\d]([-])*/))
      //   : (this.regularField = -1);
      this.dataObj.paramsAlarmList = [];
      this.changeNumber();
      if (
        this.fieldName != "机房结构高度" &&
        this.fieldName != "机房假天花高度"
      ) {
        try {
          this.fieldValue = eval(this.fieldValue);
          this.regularField = 0;
          if (this.fieldValue == undefined) {
            this.fieldValue = "";
          }
          this.ruleShow = false;
        } catch (exception) {
          this.regularField = -1;
          this.ruleShow = true;
        }
      }
      if (this.fieldName == "机房长度(X)") {
        if (this.fieldValue == "") {
          this.lengthDangerShow = false;
          this.dataObj.machineRoomForm.length = this.fieldValue;
          this.$refs.roomFieldRef.closePopup();
        }
        if (
          this.fieldValue !== "" &&
          this.regularField == 0 &&
          this.fieldValue <=
            JSON.parse(this.errorObj.examinationRoom_SideLength)[1] &&
          this.fieldValue >=
            JSON.parse(this.errorObj.examinationRoom_SideLength)[0]
        ) {
          this.ruleShow = false;
          this.dataObj.machineRoomForm.length = this.fieldValue;
          if (this.fieldValue < this.dangers.effectiveSidelength_Minimum) {
            this.lengthDangerShow = true;
          } else {
            this.lengthDangerShow = false;
          }
        } else {
          this.ruleShow = true;
        }
      }

      if (this.fieldName == "机房宽度(Y)") {
        if (this.fieldValue == "") {
          this.widthDangerShow = false;
          this.dataObj.machineRoomForm.width = this.fieldValue;
          this.$refs.roomFieldRef.closePopup();
        }
        if (
          this.fieldValue !== "" &&
          this.regularField == 0 &&
          this.fieldValue <=
            JSON.parse(this.errorObj.examinationRoom_SideLength)[1] &&
          this.fieldValue >=
            JSON.parse(this.errorObj.examinationRoom_SideLength)[0]
        ) {
          this.ruleShow = false;
          this.dataObj.machineRoomForm.width = this.fieldValue;
          if (this.fieldValue < this.dangers.effectiveSidelength_Minimum) {
            this.widthDangerShow = true;
          } else {
            this.widthDangerShow = false;
          }
        } else {
          this.ruleShow = true;
        }
      }
      if (this.fieldName == "机房结构高度") {
        if (this.fieldValue == "") {
          this.dataObj.machineRoomForm.structureHeight = this.fieldValue;
        }
        if (
          this.fieldValue !== "" &&
          this.fieldValue.search(/[^0-9\>\≥]/) == -1 &&
          this.fieldValue.replace(/[^0-9]/gi, "") <=
            this.errorObj.computerRoom_MaximumHeight &&
          this.fieldValue.replace(/[^0-9]/gi, "") >=
            this.errorObj.computerRoom_MinimumHeight
        ) {
          this.ruleShow = false;
          this.dataObj.machineRoomForm.structureHeight = this.fieldValue;
        } else {
          this.ruleShow = true;
        }
      }

      if (this.fieldName == "机房假天花高度") {
        if (this.fieldValue == "") {
          this.ceilingHeightDangerShow = false;
          this.dataObj.machineRoomForm.ceilingHeight = this.fieldValue;
        }
        if (
          this.fieldValue !== "" &&
          this.fieldValue.search(/[^0-9\>\≥]/) == -1 &&
          this.fieldValue.replace(/[^0-9]/gi, "") <=
            this.errorObj.false_Platfond_MaximumHeight &&
          this.fieldValue.replace(/[^0-9]/gi, "") >=
            this.errorObj.false_Platfond_MinimumHeight
        ) {
          this.ruleShow = false;
          this.dataObj.machineRoomForm.ceilingHeight = this.fieldValue;
          if (
            this.fieldValue.replace(/[^0-9]/gi, "") <=
            this.dataObj.machineRoomForm.structureHeight.replace(/[^0-9]/gi, "")
          ) {
            this.ruleShow = false;
          } else {
            this.ruleShow = true;
          }
          if (
            this.fieldValue.replace(/[^0-9]/gi, "") <
            this.dangers.false_Platfond_MinimumHeight
          ) {
            this.ceilingHeightDangerShow = true;
          } else {
            this.ceilingHeightDangerShow = false;
          }
        } else {
          this.ruleShow = true;
        }
      }

      if (this.fieldName == "墙厚") {
        if (this.fieldValue == "" && typeof this.fieldValue == "string") {
          this.dataObj.machineRoomForm.wallThickness = this.fieldValue;
        }
        if (
          this.fieldValue !== "" &&
          typeof this.fieldValue == "number" &&
          this.regularField == 0 &&
          this.fieldValue <= Number(this.errorObj.wall_Maximum) &&
          this.fieldValue >= Number(this.errorObj.wall_Minimum)
        ) {
          this.ruleShow = false;
          this.dataObj.machineRoomForm.wallThickness = this.fieldValue;
        } else {
          this.ruleShow = true;
        }
      }

      if (this.fieldName == "装饰层厚") {
        if (this.fieldValue == "") {
          this.dataObj.machineRoomForm.decorateThickness = this.fieldValue;
        }

        if (
          this.fieldValue !== "" &&
          this.regularField == 0 &&
          this.fieldValue <= this.errorObj.decorativeLayer_Maximum &&
          this.fieldValue >= this.errorObj.decorativeLayer_Minimum
        ) {
          this.ruleShow = false;
          this.dataObj.machineRoomForm.decorateThickness = this.fieldValue;
        } else {
          this.ruleShow = true;
        }
      }
      if (this.fieldName == "病患通道宽度") {
        if (this.fieldValue == "") {
          this.patientWidthDangerShow = false;
          this.dataObj.machineRoomForm.patientWidth = this.fieldValue;
        }
        if (
          this.dataObj.machineRoomForm.patientPosition == "上" ||
          this.dataObj.machineRoomForm.patientPosition == "下"
        ) {
          if (this.dataObj.machineRoomForm.length !== "") {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <= Number(this.dataObj.machineRoomForm.length) &&
              this.fieldValue >= this.errorObj.patientAccessWeight_Minimum
            ) {
              this.ruleShow = false;
              this.dataObj.machineRoomForm.patientWidth = this.fieldValue;
              if (this.fieldValue < this.dangers.patientAccess_RoomWeight) {
                this.patientWidthDangerShow = true;
              } else {
                this.patientWidthDangerShow = false;
              }
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.patientWidthDangerShow = false;
              this.dataObj.machineRoomForm.patientWidth = this.fieldValue;
            }
          }
        } else {
          if (this.dataObj.machineRoomForm.width !== "") {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <= Number(this.dataObj.machineRoomForm.width) &&
              this.fieldValue >= this.errorObj.patientAccessWeight_Minimum
            ) {
              this.ruleShow = false;
              this.dataObj.machineRoomForm.patientWidth = this.fieldValue;
              if (this.fieldValue < this.dangers.patientAccess_RoomWeight) {
                this.patientWidthDangerShow = true;
              } else {
                this.patientWidthDangerShow = false;
              }
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.patientWidthDangerShow = false;
              this.dataObj.machineRoomForm.patientWidth = this.fieldValue;
            }
          }
        }
      }
      if (this.fieldName == "病患通道位置") {
        if (this.fieldValue == "") {
          this.dataObj.machineRoomForm.patientLocation = this.fieldValue;
        }
        if (
          this.dataObj.machineRoomForm.patientPosition == "上" ||
          this.dataObj.machineRoomForm.patientPosition == "下"
        ) {
          if (
            this.dataObj.machineRoomForm.length !== "" &&
            this.dataObj.machineRoomForm.patientWidth !== ""
          ) {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <=
                this.dataObj.machineRoomForm.length -
                  this.dataObj.machineRoomForm.patientWidth &&
              this.fieldValue >=
                Number(this.errorObj.patientAccessLocation_Minimum)
            ) {
              this.ruleShow = false;
              this.dataObj.machineRoomForm.patientLocation = this.fieldValue;
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.dataObj.machineRoomForm.patientLocation = this.fieldValue;
            }
          }
        } else {
          if (
            this.dataObj.machineRoomForm.width !== "" &&
            this.dataObj.machineRoomForm.patientWidth !== ""
          ) {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <=
                this.dataObj.machineRoomForm.width -
                  this.dataObj.machineRoomForm.patientWidth &&
              this.fieldValue >=
                Number(this.errorObj.patientAccessLocation_Minimum)
            ) {
              this.ruleShow = false;
              this.dataObj.machineRoomForm.patientLocation = this.fieldValue;
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.dataObj.machineRoomForm.patientLocation = this.fieldValue;
            }
          }
        }
      }

      if (this.fieldName == "走廊宽度") {
        if (this.fieldValue == "") {
          this.dataObj.machineRoomForm.corridorWidth = this.fieldValue;
        }
        if (
          this.fieldValue !== "" &&
          this.regularField == 0 &&
          this.fieldValue <= this.errorObj.corridorWeight_Maximum &&
          this.fieldValue >= 0
        ) {
          this.ruleShow = false;
          this.dataObj.machineRoomForm.corridorWidth = this.fieldValue;
        } else {
          this.ruleShow = true;
        }
      }
      if (this.fieldName == "技师通道位置") {
        if (this.fieldValue == "") {
          this.dataObj.machineRoomForm.technicianLocation = this.fieldValue;
        }
        // if(this.fieldValue < this.dataObj.machineRoomForm.length){
        //   this.dataObj.machineRoomForm.
        // }
        // if (
        //   this.dataObj.machineRoomForm.length !== "" &&
        //   this.dataObj.machineRoomForm.technicianWidth !== ""
        // ) {
          if(this.fieldValue + this.dataObj.machineRoomForm.technicianWidth <= this.dataObj.machineRoomForm.length){
            this.dataObj.machineRoomForm.technicianLocation = this.fieldValue;
          }else{
            this.ruleShow = true;
          }
        //   if (
        //     this.fieldValue !== "" &&
        //     this.regularField == 0 &&
        //     this.fieldValue <=
        //       this.dataObj.machineRoomForm.length -
        //         this.dataObj.machineRoomForm.technicianWidth &&
        //     this.fieldValue >= 0
        //   ) {
        //     this.ruleShow = false;
        //     this.dataObj.machineRoomForm.technicianLocation = this.fieldValue;
        //   } else {
        //     this.ruleShow = true;
        //   }
        // } else {
        //   if (this.regularField == 0) {
        //     this.ruleShow = false;
        //     this.dataObj.machineRoomForm.technicianLocation = this.fieldValue;
        //   }
        // }
      }
      if (this.fieldName == "技师通道宽度") {
        if (this.fieldValue == "") {
          this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        }
        if(this.fieldValue + this.dataObj.machineRoomForm.technicianLocation <= this.dataObj.machineRoomForm.length){
          this.dataObj.machineRoomForm.technicianWidth = this.fieldValue
        }else{
          this.ruleShow = true;
        }
        // if (!this.isHaveKong) {
        //   if (
        //     this.dataObj.machineRoomForm.length !== "" &&
        //     this.dataObj.machineRoomForm.technicianLocation !== ""
        //   ) {
        //     if (
        //       this.fieldValue !== "" &&
        //       this.regularField == 0 &&
        //       this.fieldValue <=
        //         this.dataObj.machineRoomForm.length -
        //           this.dataObj.machineRoomForm.technicianLocation &&
        //       this.fieldValue >= 0
        //     ) {
        //       this.ruleShow = false;
        //       this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //       if (this.fieldValue == 0) {
        //         this.technicianWidthDangerShow = true;
        //       } else {
        //         this.technicianWidthDangerShow = false;
        //       }
        //     } else {
        //       this.ruleShow = true;
        //     }
        //   } else {
        //     if (this.regularField == 0) {
        //       this.ruleShow = false;
        //       this.technicianWidthDangerShow = false;
        //       this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //     }
        //   }
        //   // } else {
        //   //   if (this.fieldValue !== "" && this.regularField == 0 ) {
        //   //     this.ruleShow = false;
        //   //     this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //   //     if (this.fieldValue == 0) {
        //   //       this.technicianWidthDangerShow = true;
        //   //     } else {
        //   //       this.technicianWidthDangerShow = false;
        //   //     }
        //   //   }
        // } else {
        //   if (this.dataObj.controlRoomForm.location == 0) {
        //     if (
        //       this.dataObj.controlRoomForm.length !== "" &&
        //       this.dataObj.machineRoomForm.length !== "" &&
        //       this.dataObj.machineRoomForm.technicianLocation !== ""
        //     ) {
        //       if (
        //         this.fieldValue !== "" &&
        //         this.regularField == 0 &&
        //         this.fieldValue <=
        //           this.dataObj.controlRoomForm.location +
        //             this.dataObj.controlRoomForm.length -
        //             this.dataObj.machineRoomForm.technicianLocation &&
        //         this.fieldValue <=
        //           this.dataObj.machineRoomForm.length -
        //             this.dataObj.machineRoomForm.technicianLocation &&
        //         this.fieldValue >= 0
        //       ) {
        //         this.ruleShow = false;
        //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //         if (this.fieldValue == 0) {
        //           this.technicianWidthDangerShow = true;
        //         } else {
        //           this.technicianWidthDangerShow = false;
        //         }
        //       } else {
        //         this.ruleShow = true;
        //       }
        //     } else {
        //       if (this.regularField == 0) {
        //         this.ruleShow = false;
        //         this.technicianWidthDangerShow = false;
        //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //       }
        //     }
        //   } else if (
        //     this.dataObj.controlRoomForm.location &&
        //     this.dataObj.controlRoomForm.location > 0
        //   ) {
        //     if (
        //       this.dataObj.controlRoomForm.length !== "" &&
        //       this.dataObj.machineRoomForm.length !== "" &&
        //       this.dataObj.controlRoomForm.location !== "" &&
        //       this.dataObj.machineRoomForm.technicianLocation !== ""
        //     ) {
        //       if (
        //         this.fieldValue !== "" &&
        //         this.regularField == 0 &&
        //         this.fieldValue <=
        //           this.dataObj.controlRoomForm.location +
        //             this.dataObj.controlRoomForm.length -
        //             this.dataObj.machineRoomForm.technicianLocation &&
        //         this.fieldValue <=
        //           this.dataObj.machineRoomForm.length -
        //             this.dataObj.machineRoomForm.technicianLocation &&
        //         this.fieldValue >= 0
        //       ) {
        //         this.ruleShow = false;
        //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //         if (this.fieldValue == 0) {
        //           this.technicianWidthDangerShow = true;
        //         } else {
        //           this.technicianWidthDangerShow = false;
        //         }
        //       } else {
        //         this.ruleShow = true;
        //       }
        //     } else {
        //       if (this.regularField == 0) {
        //         this.ruleShow = false;
        //         this.technicianWidthDangerShow = false;
        //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //       }
        //     }
        //   } else {
        //     if (
        //       this.dataObj.controlRoomForm.length !== "" &&
        //       this.dataObj.machineRoomForm.length !== "" &&
        //       this.dataObj.controlRoomForm.location !== "" &&
        //       this.dataObj.machineRoomForm.technicianLocation !== ""
        //     ) {
        //       if (
        //         this.fieldValue !== "" &&
        //         this.regularField == 0 &&
        //         this.fieldValue <=
        //           this.dataObj.machineRoomForm.length -
        //             this.dataObj.machineRoomForm.technicianLocation &&
        //         this.fieldValue <=
        //           this.dataObj.controlRoomForm.length +
        //             this.dataObj.controlRoomForm.location -
        //             this.dataObj.machineRoomForm.technicianLocation &&
        //         this.fieldValue >= 0
        //       ) {
        //         this.ruleShow = false;
        //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //         if (this.fieldValue == 0) {
        //           this.technicianWidthDangerShow = true;
        //         } else {
        //           this.technicianWidthDangerShow = false;
        //         }
        //       } else {
        //         this.ruleShow = true;
        //       }
        //     } else {
        //       if (this.regularField == 0) {
        //         this.ruleShow = false;
        //         this.technicianWidthDangerShow = false;
        //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
        //       }
        //     }
        //   }
        // }
      }

      // if (this.fieldName == "技师通道宽度") {
      //   if (this.fieldValue == "") {
      //     this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //   }
      //   if (!this.isHaveKong) {
      //     if (
      //       this.dataObj.machineRoomForm.length !== "" &&
      //       this.dataObj.machineRoomForm.technicianLocation !== ""
      //     ) {
      //       if (
      //         this.fieldValue !== "" &&
      //         this.regularField == 0 &&
      //         this.fieldValue <=
      //           this.dataObj.machineRoomForm.length -
      //             this.dataObj.machineRoomForm.technicianLocation &&
      //         this.fieldValue >= 0
      //       ) {
      //         this.ruleShow = false;
      //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //         if (this.fieldValue == 0) {
      //           this.technicianWidthDangerShow = true;
      //         } else {
      //           this.technicianWidthDangerShow = false;
      //         }
      //       } else {
      //         this.ruleShow = true;
      //       }
      //     } else {
      //       if (this.regularField == 0) {
      //         this.ruleShow = false;
      //         this.technicianWidthDangerShow = false;
      //         this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //       }
      //     }
      //     // } else {
      //     //   if (this.fieldValue !== "" && this.regularField == 0 ) {
      //     //     this.ruleShow = false;
      //     //     this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //     //     if (this.fieldValue == 0) {
      //     //       this.technicianWidthDangerShow = true;
      //     //     } else {
      //     //       this.technicianWidthDangerShow = false;
      //     //     }
      //     //   }
      //   } else {
      //     if (this.dataObj.controlRoomForm.location == 0) {
      //       if (
      //         this.dataObj.controlRoomForm.length !== "" &&
      //         this.dataObj.machineRoomForm.length !== "" &&
      //         this.dataObj.machineRoomForm.technicianLocation !== ""
      //       ) {
      //         if (
      //           this.fieldValue !== "" &&
      //           this.regularField == 0 &&
      //           this.fieldValue <=
      //             this.dataObj.machineRoomForm.length -
      //               this.dataObj.machineRoomForm.technicianLocation &&
      //           this.fieldValue <=
      //             this.dataObj.controlRoomForm.length -
      //               this.dataObj.machineRoomForm.technicianLocation &&
      //           this.fieldValue >= 0
      //         ) {
      //           this.ruleShow = false;
      //           this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //           if (this.fieldValue == 0) {
      //             this.technicianWidthDangerShow = true;
      //           } else {
      //             this.technicianWidthDangerShow = false;
      //           }
      //         } else {
      //           this.ruleShow = true;
      //         }
      //       } else {
      //         if (this.regularField == 0) {
      //           this.ruleShow = false;
      //           this.technicianWidthDangerShow = false;
      //           this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //         }
      //       }
      //     } else if (
      //       this.dataObj.controlRoomForm.location &&
      //       this.dataObj.controlRoomForm.location > 0
      //     ) {
      //       if (
      //         this.dataObj.controlRoomForm.length !== "" &&
      //         this.dataObj.machineRoomForm.length !== "" &&
      //         this.dataObj.controlRoomForm.location !== "" &&
      //         this.dataObj.machineRoomForm.technicianLocation !== ""
      //       ) {
      //         if (
      //           this.fieldValue !== "" &&
      //           this.regularField == 0 &&
      //           this.fieldValue <=
      //             this.dataObj.controlRoomForm.length -
      //               this.dataObj.machineRoomForm.technicianLocation &&
      //           this.fieldValue <=
      //             this.dataObj.machineRoomForm.length -
      //               this.dataObj.controlRoomForm.location -
      //               this.dataObj.machineRoomForm.technicianLocation &&
      //           this.fieldValue >= 0
      //         ) {
      //           this.ruleShow = false;
      //           this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //           if (this.fieldValue == 0) {
      //             this.technicianWidthDangerShow = true;
      //           } else {
      //             this.technicianWidthDangerShow = false;
      //           }
      //         } else {
      //           this.ruleShow = true;
      //         }
      //       } else {
      //         if (this.regularField == 0) {
      //           this.ruleShow = false;
      //           this.technicianWidthDangerShow = false;
      //           this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //         }
      //       }
      //     } else {
      //       if (
      //         this.dataObj.controlRoomForm.length !== "" &&
      //         this.dataObj.machineRoomForm.length !== "" &&
      //         this.dataObj.controlRoomForm.location !== "" &&
      //         this.dataObj.machineRoomForm.technicianLocation !== ""
      //       ) {
      //         if (
      //           this.fieldValue !== "" &&
      //           this.regularField == 0 &&
      //           this.fieldValue <=
      //             this.dataObj.machineRoomForm.length -
      //               this.dataObj.machineRoomForm.technicianLocation &&
      //           this.fieldValue <=
      //             this.dataObj.controlRoomForm.length +
      //               this.dataObj.controlRoomForm.location -
      //               this.dataObj.machineRoomForm.technicianLocation &&
      //           this.fieldValue >= 0
      //         ) {
      //           this.ruleShow = false;
      //           this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //           if (this.fieldValue == 0) {
      //             this.technicianWidthDangerShow = true;
      //           } else {
      //             this.technicianWidthDangerShow = false;
      //           }
      //         } else {
      //           this.ruleShow = true;
      //         }
      //       } else {
      //         if (this.regularField == 0) {
      //           this.ruleShow = false;
      //           this.technicianWidthDangerShow = false;
      //           this.dataObj.machineRoomForm.technicianWidth = this.fieldValue;
      //         }
      //       }
      //     }
      //   }
      // }

      if (this.fieldName == "控制室位置") {
        if (this.fieldValue == "") {
          this.dataObj.controlRoomForm.location = this.fieldValue;
        }
        if (this.dataObj.machineRoomForm.length !== "") {
          if (
            this.fieldValue !== "" &&
            this.regularField == 0 &&
            this.fieldValue >= -this.dataObj.controlRoomForm.length &&
            this.fieldValue <= this.dataObj.machineRoomForm.length
          ) {
            this.ruleShow = false;
            this.dataObj.controlRoomForm.location = this.fieldValue;
          } else {
            this.ruleShow = true;
          }
        } else {
          if (this.regularField == 0) {
            this.ruleShow = false;
            this.dataObj.controlRoomForm.location = this.fieldValue;
          }
        }
      }
      if (this.fieldName == "控制室长度(X)") {
        if (this.fieldValue == "") {
          this.controlLengthDangerShow = false;
          this.dataObj.controlRoomForm.length = this.fieldValue;
        }
        if (
          this.fieldValue !== "" &&
          this.regularField == 0 &&
          this.fieldValue <=
            JSON.parse(this.errorObj.controlRoom_SideLength)[1] &&
          this.fieldValue >= JSON.parse(this.errorObj.controlRoom_SideLength)[0]
        ) {
          this.ruleShow = false;
          this.dataObj.controlRoomForm.length = this.fieldValue;
          if (this.fieldValue < this.dangers.controlRoom_Weight) {
            this.controlLengthDangerShow = true;
          } else {
            this.controlLengthDangerShow = false;
          }
        } else {
          this.ruleShow = true;
        }
      }
      if (this.fieldName == "控制室宽度(Y)") {
        if (this.fieldValue == "") {
          this.controlWidthDangerShow = false;
          this.dataObj.controlRoomForm.width = this.fieldValue;
        }
        if (
          this.fieldValue !== "" &&
          this.regularField == 0 &&
          this.fieldValue <=
            JSON.parse(this.errorObj.controlRoom_SideLength)[1] &&
          this.fieldValue >= JSON.parse(this.errorObj.controlRoom_SideLength)[0]
        ) {
          this.ruleShow = false;
          this.dataObj.controlRoomForm.width = this.fieldValue;
          if (this.fieldValue < this.dangers.controlRoom_Weight) {
            this.controlWidthDangerShow = true;
          } else {
            this.controlWidthDangerShow = false;
          }
        } else {
          this.ruleShow = true;
        }
      }
      if (this.fieldName == "观察窗宽度") {
        if (this.fieldValue == "") {
          this.windowWidthDangerShow = false;
          this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
        }
        if (!this.isHaveKong) {
          if (
            this.dataObj.machineRoomForm.length !== "" &&
            this.dataObj.controlRoomForm.windowLocation !== ""
          ) {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <=
                this.dataObj.machineRoomForm.length -
                  this.dataObj.controlRoomForm.windowLocation &&
              this.fieldValue >= this.errorObj.observationWindow_Weight_Minimum
            ) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
              if (this.fieldValue <= this.dangers.observationWindow_Weight) {
                this.windowWidthDangerShow = true;
              } else {
                this.windowWidthDangerShow = false;
              }
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.windowWidthDangerShow = false;
              this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
            }
          }
        } else {
          if (this.dataObj.controlRoomForm.location == 0) {
            if (
              this.dataObj.controlRoomForm.length !== "" &&
              this.dataObj.machineRoomForm.length !== "" &&
              this.dataObj.controlRoomForm.windowLocation !== ""
            ) {
              if (
                this.fieldValue !== "" &&
                this.regularField == 0 &&
                this.fieldValue <=
                  this.dataObj.machineRoomForm.length -
                    this.dataObj.controlRoomForm.windowLocation &&
                this.fieldValue <=
                  this.dataObj.controlRoomForm.length -
                    this.dataObj.controlRoomForm.windowLocation &&
                this.fieldValue >=
                  this.errorObj.observationWindow_Weight_Minimum
              ) {
                this.ruleShow = false;
                this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
                if (this.fieldValue <= this.dangers.observationWindow_Weight) {
                  this.windowWidthDangerShow = true;
                } else {
                  this.windowWidthDangerShow = false;
                }
              } else {
                this.ruleShow = true;
              }
            } else {
              if (this.regularField == 0) {
                this.ruleShow = false;
                this.windowWidthDangerShow = false;
                this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
              }
            }
          } else if (
            this.dataObj.controlRoomForm.location &&
            this.dataObj.controlRoomForm.location > 0
          ) {
            if (
              this.dataObj.controlRoomForm.length !== "" &&
              this.dataObj.machineRoomForm.length !== "" &&
              this.dataObj.controlRoomForm.location &&
              this.dataObj.controlRoomForm.windowLocation !== ""
            ) {
              if (
                this.fieldValue !== "" &&
                this.regularField == 0 &&
                this.fieldValue <=
                  this.dataObj.controlRoomForm.location +
                    this.dataObj.controlRoomForm.length -
                    this.dataObj.controlRoomForm.windowLocation &&
                this.fieldValue <=
                  this.dataObj.machineRoomForm.length -
                    this.dataObj.controlRoomForm.windowLocation &&
                this.fieldValue >=
                  this.errorObj.observationWindow_Weight_Minimum
              ) {
                this.ruleShow = false;
                this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
                if (this.fieldValue <= this.dangers.observationWindow_Weight) {
                  this.windowWidthDangerShow = true;
                } else {
                  this.windowWidthDangerShow = false;
                }
              } else {
                this.ruleShow = true;
              }
            } else {
              if (this.regularField == 0) {
                this.ruleShow = false;
                this.windowWidthDangerShow = false;
                this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
              }
            }
          } else {
            if (
              this.dataObj.controlRoomForm.length !== "" &&
              this.dataObj.machineRoomForm.length !== "" &&
              this.dataObj.controlRoomForm.location &&
              this.dataObj.controlRoomForm.windowLocation !== ""
            ) {
              if (
                this.fieldValue !== "" &&
                this.regularField == 0 &&
                this.fieldValue <=
                  this.dataObj.controlRoomForm.location +
                    this.dataObj.controlRoomForm.length -
                    this.dataObj.controlRoomForm.windowLocation &&
                this.fieldValue <=
                  this.dataObj.machineRoomForm.length -
                    this.dataObj.controlRoomForm.windowLocation &&
                this.fieldValue >=
                  this.errorObj.observationWindow_Weight_Minimum
              ) {
                this.ruleShow = false;
                this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
                if (this.fieldValue <= this.dangers.observationWindow_Weight) {
                  this.windowWidthDangerShow = true;
                } else {
                  this.windowWidthDangerShow = false;
                }
              } else {
                this.ruleShow = true;
              }
            } else {
              if (this.regularField == 0) {
                this.ruleShow = false;
                this.windowWidthDangerShow = false;
                this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
              }
            }
          }
        }
      }
      // if (this.fieldName == "观察窗宽度") {
      //   if (this.fieldValue == "") {
      //     this.windowWidthDangerShow = false;
      //     this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //   }
      //   if (!this.isHaveKong) {
      //     if (
      //       this.dataObj.machineRoomForm.length !== "" &&
      //       this.dataObj.controlRoomForm.windowLocation !== ""
      //     ) {
      //       if (
      //         this.fieldValue !== "" &&
      //         this.regularField == 0 &&
      //         this.fieldValue <=
      //           this.dataObj.machineRoomForm.length -
      //             this.dataObj.controlRoomForm.windowLocation &&
      //         this.fieldValue >= this.errorObj.observationWindow_Weight_Minimum
      //       ) {
      //         this.ruleShow = false;
      //         this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //         if (this.fieldValue <= this.dangers.observationWindow_Weight) {
      //           this.windowWidthDangerShow = true;
      //         } else {
      //           this.windowWidthDangerShow = false;
      //         }
      //       } else {
      //         this.ruleShow = true;
      //       }
      //     } else {
      //       if (this.regularField == 0) {
      //         this.ruleShow = false;
      //         this.windowWidthDangerShow = false;
      //         this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //       }
      //     }
      //     // } else {
      //     //   if (this.fieldValue !== "" && this.regularField == 0 ) {
      //     //     this.ruleShow = false;
      //     //     this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //     //     if (this.fieldValue <= this.dangers.observationWindow_Weight) {
      //     //       this.windowWidthDangerShow = true;
      //     //     } else {
      //     //       this.windowWidthDangerShow = false;
      //     //     }
      //     //   }
      //   } else {
      //     if (this.dataObj.controlRoomForm.location == 0) {
      //       if (
      //         this.dataObj.controlRoomForm.length !== "" &&
      //         this.dataObj.machineRoomForm.length !== "" &&
      //         this.dataObj.controlRoomForm.windowLocation !== ""
      //       ) {
      //         if (
      //           this.fieldValue !== "" &&
      //           this.regularField == 0 &&
      //           this.fieldValue <=
      //             this.dataObj.machineRoomForm.length -
      //               this.dataObj.controlRoomForm.windowLocation &&
      //           this.fieldValue <=
      //             this.dataObj.controlRoomForm.length -
      //               this.dataObj.controlRoomForm.windowLocation &&
      //           this.fieldValue >=
      //             this.errorObj.observationWindow_Weight_Minimum
      //         ) {
      //           this.ruleShow = false;
      //           this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //           if (this.fieldValue <= this.dangers.observationWindow_Weight) {
      //             this.windowWidthDangerShow = true;
      //           } else {
      //             this.windowWidthDangerShow = false;
      //           }
      //         } else {
      //           this.ruleShow = true;
      //         }
      //       } else {
      //         if (this.regularField == 0) {
      //           this.ruleShow = false;
      //           this.windowWidthDangerShow = false;
      //           this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //         }
      //       }
      //     } else if (
      //       this.dataObj.controlRoomForm.location &&
      //       this.dataObj.controlRoomForm.location > 0
      //     ) {
      //       if (
      //         this.dataObj.controlRoomForm.length !== "" &&
      //         this.dataObj.machineRoomForm.length !== "" &&
      //         this.dataObj.controlRoomForm.location &&
      //         this.dataObj.controlRoomForm.windowLocation !== ""
      //       ) {
      //         if (
      //           this.fieldValue !== "" &&
      //           this.regularField == 0 &&
      //           this.fieldValue <=
      //             this.dataObj.controlRoomForm.length -
      //               this.dataObj.controlRoomForm.windowLocation &&
      //           this.fieldValue <=
      //             this.dataObj.machineRoomForm.length -
      //               this.dataObj.controlRoomForm.location -
      //               this.dataObj.controlRoomForm.windowLocation &&
      //           this.fieldValue >=
      //             this.errorObj.observationWindow_Weight_Minimum
      //         ) {
      //           this.ruleShow = false;
      //           this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //           if (this.fieldValue <= this.dangers.observationWindow_Weight) {
      //             this.windowWidthDangerShow = true;
      //           } else {
      //             this.windowWidthDangerShow = false;
      //           }
      //         } else {
      //           this.ruleShow = true;
      //         }
      //       } else {
      //         if (this.regularField == 0) {
      //           this.ruleShow = false;
      //           this.windowWidthDangerShow = false;
      //           this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //         }
      //       }
      //     } else {
      //       if (
      //         this.dataObj.controlRoomForm.length !== "" &&
      //         this.dataObj.machineRoomForm.length !== "" &&
      //         this.dataObj.controlRoomForm.location &&
      //         this.dataObj.controlRoomForm.windowLocation !== ""
      //       ) {
      //         if (
      //           this.fieldValue !== "" &&
      //           this.regularField == 0 &&
      //           this.fieldValue <=
      //             this.dataObj.machineRoomForm.length -
      //               this.dataObj.controlRoomForm.windowLocation &&
      //           this.fieldValue <=
      //             this.dataObj.controlRoomForm.length +
      //               this.dataObj.controlRoomForm.location -
      //               this.dataObj.controlRoomForm.windowLocation &&
      //           this.fieldValue >=
      //             this.errorObj.observationWindow_Weight_Minimum
      //         ) {
      //           this.ruleShow = false;
      //           this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //           if (this.fieldValue <= this.dangers.observationWindow_Weight) {
      //             this.windowWidthDangerShow = true;
      //           } else {
      //             this.windowWidthDangerShow = false;
      //           }
      //         } else {
      //           this.ruleShow = true;
      //         }
      //       } else {
      //         if (this.regularField == 0) {
      //           this.ruleShow = false;
      //           this.windowWidthDangerShow = false;
      //           this.dataObj.controlRoomForm.windowWidth = this.fieldValue;
      //         }
      //       }
      //     }
      //   }
      // }
      if (this.fieldName == "观察窗位置") {
        if (this.fieldValue == "") {
          this.dataObj.controlRoomForm.windowLocation = this.fieldValue;
        }
        if (
          this.dataObj.machineRoomForm.length !== "" &&
          this.dataObj.controlRoomForm.windowWidth !== ""
        ) {
          if (
            this.fieldValue !== "" &&
            this.regularField == 0 &&
            this.fieldValue <=
              this.dataObj.machineRoomForm.length -
                this.dataObj.controlRoomForm.windowWidth &&
            this.fieldValue >= 0
          ) {
            this.ruleShow = false;
            this.dataObj.controlRoomForm.windowLocation = this.fieldValue;
          } else {
            this.ruleShow = true;
          }
        } else {
          if (this.regularField == 0) {
            this.ruleShow = false;
            this.dataObj.controlRoomForm.windowLocation = this.fieldValue;
          }
        }
      }
      if (this.fieldName == "控制室门宽度") {
        if (this.fieldValue == "") {
          this.dataObj.controlRoomForm.doorWidth = this.fieldValue;
        }
        if (this.dataObj.controlRoomForm.doorPosition == "下") {
          if (this.dataObj.controlRoomForm.length !== "") {
            if (
              this.fieldValue !== "" &&
              this.fieldValue <= this.dataObj.controlRoomForm.length &&
              this.fieldValue >= 0
            ) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorWidth = this.fieldValue;
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorWidth = this.fieldValue;
            }
          }
        } else {
          if (this.dataObj.controlRoomForm.width !== "") {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <= this.dataObj.controlRoomForm.width &&
              this.fieldValue >= 0
            ) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorWidth = this.fieldValue;
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorWidth = this.fieldValue;
            }
          }
        }
      }

      if (this.fieldName == "控制室门位置") {
        if (this.fieldValue == "") {
          this.dataObj.controlRoomForm.doorLocation = this.fieldValue;
        }
        if (this.dataObj.controlRoomForm.doorPosition == "下") {
          if (
            this.dataObj.controlRoomForm.length !== "" &&
            this.dataObj.controlRoomForm.doorWidth !== "" &&
            this.dataObj.controlRoomForm.location !== ""
          ) {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <=
                Number(this.dataObj.controlRoomForm.length) -
                  Number(this.dataObj.controlRoomForm.doorWidth) +
                  Number(this.dataObj.controlRoomForm.location) &&
              this.fieldValue >= Number(this.dataObj.controlRoomForm.location)
            ) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorLocation = this.fieldValue;
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorLocation = this.fieldValue;
            }
          }
        } else {
          if (
            this.dataObj.controlRoomForm.width !== "" &&
            this.dataObj.controlRoomForm.doorWidth !== ""
          ) {
            if (
              this.fieldValue !== "" &&
              this.regularField == 0 &&
              this.fieldValue <=
                Number(this.dataObj.controlRoomForm.width) -
                  Number(this.dataObj.controlRoomForm.doorWidth) &&
              this.fieldValue >= 0
            ) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorLocation = this.fieldValue;
            } else {
              this.ruleShow = true;
            }
          } else {
            if (this.regularField == 0) {
              this.ruleShow = false;
              this.dataObj.controlRoomForm.doorLocation = this.fieldValue;
            }
          }
        }
      }

      if (
        !this.ruleShow ||
        (this.fieldValue == "" && typeof this.fieldValue == "string")
      ) {
        this.$refs.roomFieldRef.closePopup();
      }
      //  if (this.regularField == 0) {
      //   this.$refs.roomFieldRef.closePopup();
      //   // this.ruleShow=false
      // } else {
      //   this.regularField = -1;
      //   this.ruleShow=true
      // }
    },
    choosePoint(t, n) {
      this.defaultValue = n;
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "定位点") {
        this.popupContnet.types.push(...this.pointTypes);
        this.checkedValue = this.dataObj.point;
        this.checkedValue == "左上角"
          ? (this.checkMark = 0)
          : (this.checkMark = 1);
      }
    },
    choosePatientPosition(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "病患通道方位") {
        this.checkMark = 2;
        this.popupContnet.types.push(...this.patientPosition);
        this.checkedValue = this.dataObj.machineRoomForm.patientPosition;
        if (this.checkedValue == "上") {
          this.checkMark = 0;
        } else if (this.checkedValue == "下") {
          this.checkMark = 1;
        } else if (this.checkedValue == "左") {
          this.checkMark = 2;
        } else {
          this.checkMark = 3;
        }
      }
    },
    choosePatientType(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "病患通道类型") {
        this.checkMark = 2;
        this.popupContnet.types.push(...this.patientType);
        this.checkedValue = this.dataObj.machineRoomForm.patientType;
        if (this.checkedValue == "单开门") {
          this.checkMark = 0;
        } else if (this.checkedValue == "双开门") {
          this.checkMark = 1;
        } else {
          this.checkMark = 2;
        }
      }
    },
    choosePatientDirection(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "病患通道朝向") {
        this.checkMark = 1;
        this.popupContnet.types.push(...this.patientDirection);
        this.checkedValue = this.dataObj.machineRoomForm.patientDirection;
        this.checkedValue == "走廊"
          ? (this.checkMark = 0)
          : (this.checkMark = 1);
      }
    },
    chooseSlideDirection(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "平移门滑动方向") {
        this.popupContnet.types.push(...this.slideDirection);
        this.checkedValue = this.dataObj.machineRoomForm.slideDirection;
        if (this.checkedValue == "上" || this.checkedValue == "左") {
          this.checkMark = 0;
        } else {
          this.checkMark = 1;
        }
      }
    },
    chooseTechnicianDirection(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "技师通道朝向") {
        this.checkMark = 1;
        this.popupContnet.types.push(...this.technicianDirection);
        this.checkedValue = this.dataObj.machineRoomForm.technicianDirection;
        this.checkedValue == "开向控制室"
          ? (this.checkMark = 0)
          : (this.checkMark = 1);
      }
    },
    chooseTechnicianType(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "技师通道类型") {
        this.checkMark = 0;
        this.popupContnet.types.push(...this.technicianType);
        this.checkedValue = this.dataObj.machineRoomForm.technicianType;
        this.checkedValue == "单开门"
          ? (this.checkMark = 0)
          : (this.checkMark = 1);
      }
    },
    chooseDoorType(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "控制室门类型") {
        this.checkMark = 0;
        this.checkedValue = this.dataObj.controlRoomForm.doorType;
        this.popupContnet.types.push(...this.controlDoorType);
        this.checkedValue == "单开门"
          ? (this.checkMark = 0)
          : (this.checkMark = 1);
      }
    },
    chooseDoorPosition(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "控制室门方位") {
        this.checkMark = 2;
        this.popupContnet.types.push(...this.doorPositionType);
        this.checkedValue = this.dataObj.controlRoomForm.doorPosition;
        if (this.checkedValue == "下") {
          this.checkMark = 0;
        } else if (this.checkedValue == "左") {
          this.checkMark = 1;
        } else {
          this.checkMark = 2;
        }
      }
    },
    chooseDoorDirection(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "控制室门朝向") {
        this.checkMark = 0;
        this.popupContnet.types.push(...this.doorDirectionType);
        this.checkedValue = this.dataObj.controlRoomForm.doorDirection;
        this.checkedValue == "开向控制室"
          ? (this.checkMark = 0)
          : (this.checkMark = 1);
      }
    },
    chooseWiring(t) {
      this.$refs.popupRef.openPopup();
      this.popupContnet.title = t;
      this.popupContnet.types = [];
      if (t == "布线方式") {
        this.checkMark = 0;
        this.popupContnet.types.push(...this.wiringType);
        this.checkedValue = this.dataObj.wiring;
        this.checkedValue == "电缆沟布线"
          ? (this.checkMark = 0)
          : (this.checkMark = 1);
      }
    },
    zhuLocationXBlur(name, value) {
      if (this.$refs.form.validate) {
        this.$refs.form
          .validate(name)
          .then(() => {})
          .catch(() => {});
      }
    },
    zhuLocationYBlur(name, value) {
      if (this.$refs.form.validate) {
        this.$refs.form
          .validate(name)
          .then(() => {})
          .catch(() => {});
      }
    },
    zhuLengthBlur(name, value) {
      if (this.$refs.form.validate) {
        this.$refs.form
          .validate(name)
          .then(() => {})
          .catch(() => {});
      }
    },
    zhuWidthBlur(name, value) {
      if (this.$refs.form.validate) {
        this.$refs.form
          .validate(name)
          .then(() => {})
          .catch(() => {});
      }
    },
    // 打开设置柱子弹框
    setZhuParams(a) {
      console.log(a);
      this.zhuIndex = a;
      this.zhuParamsForm.zhuLocationX = this.dataObj.zhuForm[this.zhuIndex - 1]
        ? this.dataObj.zhuForm[this.zhuIndex - 1].x
        : "";
      this.zhuParamsForm.zhuLocationY = this.dataObj.zhuForm[this.zhuIndex - 1]
        ? this.dataObj.zhuForm[this.zhuIndex - 1].y
        : "";
      this.zhuParamsForm.zhuLength = this.dataObj.zhuForm[this.zhuIndex - 1]
        ? this.dataObj.zhuForm[this.zhuIndex - 1].height
        : "";
      this.zhuParamsForm.zhuWidth = this.dataObj.zhuForm[this.zhuIndex - 1]
        ? this.dataObj.zhuForm[this.zhuIndex - 1].width
        : "";
      this.$refs.popupRef1.openPopup();
      this.popupContnet.types = [];
      this.ZhuZi = true;
    },

    // 取消设置柱子
    zhuCancel() {
      this.$refs.popupRef1.closePopup();
      this.dataObj.zhuForm.splice(this.zhuIndex - 1, 1);
      this.popupContnet.types = [];
      this.ZhuZi = false;
    },
    // 确定设置柱子
    onSubmitZhu() {
      this.$refs.popupRef1.closePopup();
      let obj = {};
      obj.x = Number(this.zhuParamsForm.zhuLocationX);
      obj.y = Number(this.zhuParamsForm.zhuLocationY);
      obj.width = Number(this.zhuParamsForm.zhuWidth);
      obj.height = Number(this.zhuParamsForm.zhuLength);
      this.dataObj.zhuForm[this.zhuIndex - 1] = obj;
      console.log("zhuForm", this.dataObj.zhuForm);
      this.popupContnet.types = [];
      this.ZhuZi = false;
    },
    onFailedZhu(ee) {
      console.log(ee);
      console.log(this.errorObj, "errorObj");
    },
    // 弹框确定
    popupConfirm() {
      if (this.popupContnet.title == "定位点") {
        this.dataObj.point = this.checkedValue;
      }
      if (this.popupContnet.title == "病患通道方位") {
        this.dataObj.machineRoomForm.patientPosition = this.checkedValue;
        if (this.checkedValue == "左" || this.checkedValue == "右") {
          if(this.dataObj.machineRoomForm.slideDirection != "上" && this.dataObj.machineRoomForm.slideDirection != "下"){
            this.dataObj.machineRoomForm.slideDirection = "上";
          }
          this.checkMark = 0;
        } else {
          if(this.dataObj.machineRoomForm.slideDirection != "左" && this.dataObj.machineRoomForm.slideDirection != "右"){
            this.dataObj.machineRoomForm.slideDirection = "左";
          }
          this.checkMark = 2;
        }
      }
      if (this.popupContnet.title == "病患通道类型") {
        this.dataObj.machineRoomForm.patientType = this.checkedValue;
        if (this.dataObj.machineRoomForm.patientType == "平移门") {
          this.slideDoorShow = true;
        } else {
          this.slideDoorShow = false;
        }
      }
      if (this.popupContnet.title == "病患通道朝向") {
        this.dataObj.machineRoomForm.patientDirection = this.checkedValue;
      }

      if (this.popupContnet.title == "技师通道朝向") {
        this.dataObj.machineRoomForm.technicianDirection = this.checkedValue;
      }
      if (this.popupContnet.title == "技师通道类型") {
        this.dataObj.machineRoomForm.technicianType = this.checkedValue;
      }
      if (this.popupContnet.title == "控制室门类型") {
        this.dataObj.controlRoomForm.doorType = this.checkedValue;
      }
      if (this.popupContnet.title == "控制室门方位") {
        this.dataObj.controlRoomForm.doorPosition = this.checkedValue;
      }
      if (this.popupContnet.title == "控制室门朝向") {
        this.dataObj.controlRoomForm.doorDirection = this.checkedValue;
      }
      if (this.popupContnet.title == "布线方式") {
        this.dataObj.wiring = this.checkedValue;
      }
      if (
        this.dataObj.machineRoomForm.patientPosition == "上" ||
        this.dataObj.machineRoomForm.patientPosition == "下"
      ) {
        // this.dataObj.machineRoomForm.slideDirection = "左";
        if(this.dataObj.machineRoomForm.slideDirection != "左" && this.dataObj.machineRoomForm.slideDirection != "右"){
          this.dataObj.machineRoomForm.slideDirection = "左";
        }
        let arr1 = ["左", "右"];
        this.slideDirection = arr1;
        if (this.popupContnet.title == "平移门滑动方向") {
          this.dataObj.machineRoomForm.slideDirection = this.checkedValue;
        }
      } else {
        // this.dataObj.machineRoomForm.slideDirection = "上";
        if(this.dataObj.machineRoomForm.slideDirection != "上" && this.dataObj.machineRoomForm.slideDirection != "下"){
          this.dataObj.machineRoomForm.slideDirection = "上";
        }
        let arr2 = ["上", "下"];
        this.slideDirection = arr2;
        if (this.popupContnet.title == "平移门滑动方向") {
          this.dataObj.machineRoomForm.slideDirection = this.checkedValue;
        }
      }
      if (this.checkedValue) {
        this.$refs.popupRef.closePopup();
        this.popupContnet.types = [];
        this.popupContnet.title = "";
      }
      this.checkedValue = "";
    },

    closeIcon() {
      this.regularField = -2;
      this.checkMark = 0;
      this.ZhuZi = false;
      this.dangerShow = false;
      this.partsPopup = "";
      this.typePopupShow = false;
    },
    choseType(item, index) {
      this.checkMark = index;
      this.checkedValue = item;
    },
    switchChange(e, id, item) {
      console.log(e, id, item, this.optionalRadioList);
      Array.prototype.remove = function (val) {
        var index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (e && !this.dataObj.deviceIdList.includes(id)) {
        this.dataObj.deviceIdList.push(id);
        this.optionalRadioIdList.push(id);
      } else {
        this.dataObj.deviceIdList.remove(id);
        this.optionalRadioIdList.remove(id);
      }
    },
    switchChangeZhu(e) {
      if (e) {
        this.isHaveZhu = true;
        // this.dataObj.zhuForm.zhuNum = 1;
      } else {
        this.isHaveZhu = false;
        this.templateList = [];
        this.zhuNum = "";
        this.dataObj.zhuForm = [];
      }
    },
    switchChangeKong(e) {
      sessionStorage.setItem('kongzhishi', e)
      console.log(e, '5555555555555')
      this.dataObj.paramsAlarmList = [];
      if (e) {
        this.isHaveKong = true;
      } else {
        this.isHaveKong = false;
        this.controlRoomForm.length = 0
        this.controlRoomForm.width = 0
        this.controlRoomForm.doorLocation = 0
        this.controlRoomForm.doorWidth = 0

      }
      this.dangerInit();
    },

    fieldInit() {
      //  初始化的时候判断病患通道类型是否是平移门
      if (this.dataObj.machineRoomForm.patientType == "平移门") {
        this.slideDoorShow = true;
        if (
          this.dataObj.machineRoomForm.patientPosition == "上" ||
          this.dataObj.machineRoomForm.patientPosition == "下"
        ) {
          this.dataObj.machineRoomForm.slideDirection = "左";
          let arr1 = ["左", "右"];
          this.slideDirection = arr1;
          this.checkMark = 2;
        } else {
          this.dataObj.machineRoomForm.slideDirection = "上";
          let arr2 = ["上", "下"];
          this.slideDirection = arr2;
          this.checkMark = 0;
        }
      }
    },
    zhuBlur() {
      // this.dataObj.zhuForm.zhuNum = this.zhuNum;
      this.templateList = [...Array(Number(this.zhuNum))].map((k, i) => i + 1);
      console.log(this.templateList.length);
      if (this.templateList.length == 0) {
        this.dataObj.zhuForm = [];
      }
    },

    dangerInit() {
      // 风险触发列表
      // if (
      //   Number(this.dataObj.machineRoomForm.length) *
      //     Number(this.dataObj.machineRoomForm.width) <
      //   300000
      if (
        Number(this.dataObj.machineRoomForm.length) *
          Number(this.dataObj.machineRoomForm.width) <
        this.dangers.effectiveArea*10000
      ) {
        let obj = {
          point: {
            x: Number(this.dataObj.machineRoomForm.length) + 30,
            y: -50,
          },
          message: this.dangers.effectiveAreaTips,
        };
        if (
          this.dataObj.paramsAlarmList.some(
            (item) => item.message == obj.message
          ) == false
        ) {
          this.dataObj.paramsAlarmList.push(obj);
        }
      }
      if (this.lengthDangerShow == true) {
        let obj = {
          point: {
            x: Number(this.dataObj.machineRoomForm.length) / 2,
            y: 0,
          },
          message: this.dangers.effectiveAreaTips,
        };
        if (
          this.dataObj.paramsAlarmList.some(
            (item) => item.message == obj.message
          ) == false
        ) {
          this.dataObj.paramsAlarmList.push(obj);
        }
      }
      if (this.widthDangerShow == true) {
        let obj = {
          point: {
            x: Number(this.dataObj.machineRoomForm.width) / 2,
            y: 0,
          },
          message: this.dangers.effectiveAreaTips,
        };
        if (
          this.dataObj.paramsAlarmList.some(
            (item) => item.message == obj.message
          ) == false
        ) {
          this.dataObj.paramsAlarmList.push(obj);
        }
      }
      if (this.ceilingHeightDangerShow == true) {
        let obj = {
          point: {
            x: 0,
            y: 0,
          },

          message: this.dangers.false_Platfond_Tips,
        };
        if (
          this.dataObj.paramsAlarmList.some(
            (item) => item.message == obj.message
          ) == false
        ) {
          this.dataObj.paramsAlarmList.push(obj);
        }
      }
      if (this.patientWidthDangerShow == true) {
        let obj = {};
        if (this.dataObj.machineRoomForm.patientPosition == "上") {
          obj = {
            point: {
              x: Number(this.dataObj.machineRoomForm.patientLocation),
              y: 0,
            },
            message: this.dangers.checkRoomTips,
          };
          if (
            this.dataObj.paramsAlarmList.some(
              (item) => item.message == obj.message
            ) == false
          ) {
            this.dataObj.paramsAlarmList.push(obj);
          }
        } else if (this.dataObj.machineRoomForm.patientPosition == "下") {
          obj = {
            point: {
              x: Number(this.dataObj.machineRoomForm.patientLocation),
              y: Number(this.dataObj.machineRoomForm.width),
            },
            message: this.dangers.checkRoomTips,
          };
          if (
            this.dataObj.paramsAlarmList.some(
              (item) => item.message == obj.message
            ) == false
          ) {
            this.dataObj.paramsAlarmList.push(obj);
          }
        } else if (this.dataObj.machineRoomForm.patientPosition == "左") {
          obj = {
            point: {
              x: 0,
              y: Number(this.dataObj.machineRoomForm.patientLocation),
            },
            message: this.dangers.checkRoomTips,
          };
          if (
            this.dataObj.paramsAlarmList.some(
              (item) => item.message == obj.message
            ) == false
          ) {
            this.dataObj.paramsAlarmList.push(obj);
          }
        } else {
          obj = {
            point: {
              x: Number(this.dataObj.machineRoomForm.length),
              y: Number(this.dataObj.machineRoomForm.patientLocation),
            },
            message: this.dangers.checkRoomTips,
          };
          if (
            this.dataObj.paramsAlarmList.some(
              (item) => item.message == obj.message
            ) == false
          ) {
            this.dataObj.paramsAlarmList.push(obj);
          }
        }
      }
      if (this.technicianWidthDangerShow == true) {
        let obj = {
          point: {
            x: 0,
            y: 0,
          },
          message: this.dangers.technicianAccessTips,
        };
        if (
          this.dataObj.paramsAlarmList.some(
            (item) => item.message == obj.message
          ) == false
        ) {
          this.dataObj.paramsAlarmList.push(obj);
        }
      }
      if (this.windowWidthDangerShow == true) {
        let obj = {
          point: {
            x: Number(this.dataObj.controlRoomForm.windowLocation),
            y: Number(this.dataObj.machineRoomForm.width),
          },
          message: this.dangers.observationWindow_Tips,
        };
        if (
          this.dataObj.paramsAlarmList.some(
            (item) => item.message == obj.message
          ) == false
        ) {
          this.dataObj.paramsAlarmList.push(obj);
        }
      }
      // 无控制室时才有控制室的风险提示
      if (this.isHaveKong) {
        if (this.controlLengthDangerShow == true) {
          let obj = {
            point: {
              x: Number(this.dataObj.controlRoomForm.length) / 2,
              y:
                Number(this.dataObj.machineRoomForm.width) +
                Number(this.dataObj.machineRoomForm.wallThickness),
            },
            message: this.dangers.controlRoom_Tips,
          };
          if (
            this.dataObj.paramsAlarmList.some(
              (item) => item.message == obj.message
            ) == false
          ) {
            this.dataObj.paramsAlarmList.push(obj);
          }
        }
        if (this.controlWidthDangerShow == true) {
          let obj = {
            point: {
              x: Number(this.dataObj.controlRoomForm.length) / 2,
              y:
                Number(this.dataObj.machineRoomForm.width) +
                Number(this.dataObj.machineRoomForm.wallThickness) +
                Number(this.dataObj.controlRoomForm.width) / 2,
            },

            message: this.dangers.controlRoom_Tips,
          };
          if (
            this.dataObj.paramsAlarmList.some(
              (item) => item.message == obj.message
            ) == false
          ) {
            this.dataObj.paramsAlarmList.push(obj);
          }
        }
      }
    },
    stringToNumber() {
      if (this.copyDataObj.machineRoomForm.patientDirection == "走廊") {
        this.copyDataObj.machineRoomForm.patientDirection = 0;
      } else {
        this.copyDataObj.machineRoomForm.patientDirection = 1;
      }
      if (this.copyDataObj.machineRoomForm.patientPosition == "上") {
        this.copyDataObj.machineRoomForm.patientPosition = 0;
      } else if (this.copyDataObj.machineRoomForm.patientPosition == "左") {
        this.copyDataObj.machineRoomForm.patientPosition = 2;
      } else if (this.copyDataObj.machineRoomForm.patientPosition == "右") {
        this.copyDataObj.machineRoomForm.patientPosition = 3;
      } else {
        this.copyDataObj.machineRoomForm.patientPosition = 1;
      }
      if (this.copyDataObj.machineRoomForm.patientType == "单开门") {
        this.copyDataObj.machineRoomForm.patientType = 0;
      } else if (this.copyDataObj.machineRoomForm.patientType == "双开门") {
        this.copyDataObj.machineRoomForm.patientType = 1;
      } else {
        this.copyDataObj.machineRoomForm.patientType = 2;
      }

      if (this.copyDataObj.machineRoomForm.slideDirection == "上") {
        this.copyDataObj.machineRoomForm.slideDirection = 1;
      } else if (this.copyDataObj.machineRoomForm.slideDirection == "下") {
        this.copyDataObj.machineRoomForm.slideDirection = 0;
      } else if (this.copyDataObj.machineRoomForm.slideDirection == "左") {
        this.copyDataObj.machineRoomForm.slideDirection = 0;
      } else {
        this.copyDataObj.machineRoomForm.slideDirection = 1;
      }
      // if (this.copyDataObj.machineRoomForm.slideDirection == '上') {
      //   this.copyDataObj.machineRoomForm.slideDirection = 0
      // } else if (this.copyDataObj.machineRoomForm.slideDirection == '下') {
      //   this.copyDataObj.machineRoomForm.slideDirection = 1
      // } else if (this.copyDataObj.machineRoomForm.slideDirection == '左') {
      //   this.copyDataObj.machineRoomForm.slideDirection = 3
      // } else {
      //   this.copyDataObj.machineRoomForm.slideDirection = 2
      // }
      if (
        this.copyDataObj.machineRoomForm.technicianDirection == "开向控制室"
      ) {
        this.copyDataObj.machineRoomForm.technicianDirection = 0;
      } else {
        this.copyDataObj.machineRoomForm.technicianDirection = 1;
      }

      if (this.copyDataObj.machineRoomForm.technicianType == "单开门") {
        this.copyDataObj.machineRoomForm.technicianType = 0;
      } else {
        this.copyDataObj.machineRoomForm.technicianType = 1;
      }
      if (this.copyDataObj.controlRoomForm.doorType == "单开门") {
        this.copyDataObj.controlRoomForm.doorType = 0;
      } else {
        this.copyDataObj.controlRoomForm.doorType = 1;
      }
      if (this.copyDataObj.controlRoomForm.doorDirection == "开向走廊") {
        this.copyDataObj.controlRoomForm.doorDirection = 0;
      } else {
        this.copyDataObj.controlRoomForm.doorDirection = 1;
      }

      if (this.copyDataObj.controlRoomForm.doorPosition == "下") {
        this.copyDataObj.controlRoomForm.doorPosition = 1;
      } else if (this.copyDataObj.controlRoomForm.doorPosition == "左") {
        this.copyDataObj.controlRoomForm.doorPosition = 2;
      } else if (this.copyDataObj.controlRoomForm.doorPosition == "右") {
        this.copyDataObj.controlRoomForm.doorPosition = 3;
      } else {
        this.copyDataObj.controlRoomForm.doorPosition = 0;
      }

      if (this.copyDataObj.point == "左上角") {
        this.copyDataObj.point = 0;
      } else {
        this.copyDataObj.point = 1;
      }
      if (this.copyDataObj.wirelessRepeater == true) {
        this.copyDataObj.wirelessRepeater = 1;
      } else {
        this.copyDataObj.wirelessRepeater = 0;
      }
      if (this.copyDataObj.wiring == "电缆沟布线") {
        this.copyDataObj.wiring = 0;
      } else {
        this.copyDataObj.wiring = 1;
      }
    },
    // 计算技师通道和观察窗位置是否合理
    testParameter() {
      if (this.dataObj.point == "左上角") {
        //定位点左
        if (
          this.dataObj.machineRoomForm.technicianLocation <
          this.dataObj.controlRoomForm.windowLocation
        ) {
          if (
            this.dataObj.machineRoomForm.technicianLocation +
              this.dataObj.machineRoomForm.technicianWidth >
            this.dataObj.controlRoomForm.windowLocation
          ) {
            Dialog.alert({
              message: " 技师通道位置与观察窗重叠，\n\n请重新输入！",
            });
            this.isReasonable = false;
          }
        } else if (
          this.dataObj.machineRoomForm.technicianLocation >
          this.dataObj.controlRoomForm.windowLocation
        ) {
          if (
            this.dataObj.controlRoomForm.windowLocation +
              this.dataObj.controlRoomForm.windowWidth >
            this.dataObj.machineRoomForm.technicianLocation
          ) {
            Dialog.alert({
              message: " 技师通道位置与观察窗重叠，\n\n请重新输入！",
            });
            this.isReasonable = false;
          }
        } else {
          Dialog.alert({
            message: " 技师通道位置与观察窗重叠，\n\n请重新输入！",
          });
          this.isReasonable = false;
        }
      } else {
        //定位点右
        if (
          this.dataObj.machineRoomForm.length -
            this.dataObj.machineRoomForm.technicianLocation -
            this.dataObj.machineRoomForm.technicianWidth <
          this.dataObj.machineRoomForm.length -
            this.dataObj.controlRoomForm.windowLocation -
            this.dataObj.controlRoomForm.windowWidth
        ) {
          if (
            this.dataObj.machineRoomForm.length -
              this.dataObj.machineRoomForm.technicianLocation -
              this.dataObj.machineRoomForm.technicianWidth +
              this.dataObj.machineRoomForm.technicianWidth >
            this.dataObj.machineRoomForm.length -
              this.dataObj.controlRoomForm.windowWidth -
              this.dataObj.controlRoomForm.windowLocation
          ) {
            Dialog.alert({
              message: " 技师通道位置与观察窗重叠，\n\n请重新输入！",
            });
            this.isReasonable = false;
          }
        } else if (
          this.dataObj.machineRoomForm.length -
            this.dataObj.machineRoomForm.technicianLocation -
            this.dataObj.machineRoomForm.technicianWidth >
          this.dataObj.machineRoomForm.length -
            this.dataObj.controlRoomForm.windowLocation -
            this.dataObj.controlRoomForm.windowWidth
        ) {
          if (
            this.dataObj.machineRoomForm.length -
              this.dataObj.controlRoomForm.windowWidth -
              this.dataObj.controlRoomForm.windowLocation +
              this.dataObj.controlRoomForm.windowWidth >
            this.dataObj.machineRoomForm.length -
              this.dataObj.machineRoomForm.technicianLocation -
              this.dataObj.machineRoomForm.technicianWidth
          ) {
            Dialog.alert({
              message: " 技师通道位置与观察窗重叠，\n\n请重新输入！",
            });
            this.isReasonable = false;
          }
        } else {
          Dialog.alert({
            message: " 技师通道位置与观察窗重叠，\n\n请重新输入！",
          });
          this.isReasonable = false;
        }
      }
    },
    onSubmit() {
      if(this.dataObj.zhuForm.length > 0){
        this.dataObj.zhuForm.forEach(val => {
          for(let i in val){
            val[i] = Math.floor(val[i])
          }
        })
        sessionStorage.setItem("zhuzi", JSON.stringify(this.dataObj.zhuForm))
      }
      sessionStorage.setItem('kongzhishi', this.kongChecked)
      // if (this.dataObj.machineRoomForm.slideDirection == '上') {
      //   this.dataObj.machineRoomForm.slideDirection = 1
      // } else if (this.dataObj.machineRoomForm.slideDirection == '下') {
      //   this.dataObj.machineRoomForm.slideDirection = 0
      // } else if (this.dataObj.machineRoomForm.slideDirection == '左') {
      //   this.dataObj.machineRoomForm.slideDirection = 0
      // } else {
      //   this.dataObj.machineRoomForm.slideDirection = 1
      // }
      // console.log(this.dataObj,'55555555555555555555555')
      // this.dataObj.controlRoomForm.doorLocation = this.dataObj.controlRoomForm.doorLocation + this.dataObj.controlRoomForm.location
      // if(this.dataObj.zhuForm.length > 0){
      //   this.dataObj.zhuForm.forEach((val) => {
      //     if(this.dataObj.point === '右上角'){
      //       val.x = val.x-val.height
      //     }
      //   })
      // }
      // var getDeviceTypeResult = JSON.parse(sessionStorage.getItem('setDeviceTypeResult'))
      // getDeviceTypeResult.forEach(val => {
      //   if(val.name === '现场配电箱'){
      //     this.dataObj.deviceIdList.push(val.id)
      //   }
      //   if(val.name === '空调'){
      //     this.dataObj.deviceIdList.push(val.id)
      //   }
      // })
      // this.dataObj.deviceIdList.push()
      // console.log(this.dataObj,'555555555555555555555559999999999999')
      this.$bus.$emit("statusDrag");
      this.isReasonable = true;
      this.testParameter();
      sessionStorage.removeItem("optionalRadioList");
      sessionStorage.setItem(
        "optionalRadioList",
        JSON.stringify(this.optionalRadioList)
      );
      sessionStorage.setItem("isHaveKong", this.isHaveKong);
      if (!this.dataObj.deviceIdList.includes(...this.optionalRadioIdList)) {
        this.dataObj.deviceIdList.push(...this.optionalRadioIdList);
      }
      this.dangerInit();
      this.dataObj.examinationCouchId = this.examinationCouchId;
      this.dataObj.examinationCouchName = this.examinationCouchName;
      if(localStorage.getItem('system').indexOf('Symbia') === 0){
        this.dataObj.examinationCouchId = this.dataObj.deviceIdList[0]
      }
      // 点击下一步,把多选的选中配件id提交
      for (let i in this.requiredMultipleList) {
        this.requiredMultipleList[i].deviceId && !this.dataObj.deviceIdList.includes(this.requiredMultipleList[i].deviceId)
          ? this.dataObj.deviceIdList.push(
              this.requiredMultipleList[i].deviceId
            )
          : "";
      }
      this.copyDataObj = JSON.parse(JSON.stringify(this.dataObj));
      this.stringToNumber();
      localStorage.setItem("data", JSON.stringify(this.copyDataObj));
      sessionStorage.setItem("homeData", JSON.stringify(this.dataObj));
      if (this.dataObj.paramsAlarmList.length > 0) {
        this.$refs.popupRef.openPopup();
        this.popupContnet.types = [];
        this.dangerShow = true;
        this.popupContnet.title = "风险提示";
      } else {
        if(this.isReasonable != false){
          this.beforeNextStep();
        }
        
      }
      // if (this.isReasonable == false) {
      // }
      console.log("首页提交", this.dataObj, this.copyDataObj);
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
      this.dangerShow = false;
    },
    dangerCancel() {
      let tempArr = [];
      for (let i in this.requiredMultipleList) {
        if (this.requiredMultipleList[i].default == "左手位") {
          this.requiredMultipleList.splice(i, 1);
        }
        tempArr.push(this.requiredMultipleList[i].deviceUiValue);
        if (this.requiredMultipleList[i].deviceUiValue == "请选择") {
          Dialog.alert({
            message: "请选择" + this.requiredMultipleList[i].series,
          }).then(() => {});
        }
      }
      this.$refs.popupRef.closePopup();
      this.dataObj.paramsAlarmList = [];
    },
    dangerConfirm() {
      this.beforeNextStep();
      // this.$router.push({ name: "2Droom" });
      this.$refs.popupRef.closePopup();
    },
    beforeNextStep() {
      let tempArr = [];
      if (this.requiredMultipleList.length == 0) {
        this.$router.push({ name: "2Droom" });
      }
      console.log(this.requiredMultipleList, '----------------')
      for (let i in this.requiredMultipleList) {
        if (this.requiredMultipleList[i].default == "左手位") {
          this.requiredMultipleList.splice(i, 1);
        }
        
        tempArr.push(this.requiredMultipleList[i].deviceUiValue);
        if(localStorage.getItem('system').indexOf('Symbia') !== 0){
          if (this.requiredMultipleList[i].deviceUiValue == "请选择") {
            Dialog.alert({
              message: "请选择" + this.requiredMultipleList[i].series,
            }).then(() => {});
          }
          if (!tempArr.includes("请选择") && this.isReasonable) {
            this.$router.push({ name: "2Droom" });
          }
        }else{
          if(this.requiredMultipleList[i].deviceUiValue == "请选择"){
            if(this.requiredMultipleList[i].series == '检查床'){
              this.$router.push({ name: "2Droom" });
            }
            // else{
            //    Dialog.alert({
            //     message: "请选择" + this.requiredMultipleList[i].series,
            //   }).then(() => {});
            // }
          }
          
        }
        
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  padding-bottom: 30px;

  .product-list {
    .product-item {
      border-bottom: 1px solid #eee;
      padding: 27px 23px;
      font-weight: bold;
      color: #404040;
      font-size: 24px;
      display: flex;
      justify-content: space-between;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .item-btn {
        color: #ec6602;
      }
    }
    .product-item1 {
      padding: 5px 0;
    }
    .alone {
      padding-right: 31px;
    }
  }
}
.haveZhu {
  .zhu-list {
    background-color: #fff;
    margin-bottom: 10px;
    padding-left: 17px;
    margin-top: -10px;
    // line-height: 41px;
    .zhu-item {
      border-bottom: 1px solid #eee;
      padding: 27px 25px;
      font-weight: bold;
      color: #404040;
      font-size: 24px;
      display: flex;
      justify-content: space-between;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
    }
    .zhu-num {
      font-weight: bold;
      color: #404040;
      font-size: 24px;
    }
    .zhu-btn {
      font-size: 24px;
      font-weight: bold;
      padding: 0 23px;
    }
  }
}
.alonebgc {
  background-color: #fff;
  margin-bottom: 10px;
  padding-left: 10px;
}
.alonepoint {
  font-weight: 500;
  color: #000000;
  font-size: 26px;
}
::v-deep .van-cell--large {
  padding: 30px 33px;
}
.van-collapse-item {
  margin-bottom: 10px;
}
::v-deep .van-collapse-item__title--expanded::after {
  display: none;
}
::v-deep .van-cell--large .van-cell__title {
  font-weight: 500;
  color: #000000;
  font-size: 26px;
}
::v-deep .van-key--blue {
  background-color: #ec6602;
}
// 表单
.van-cell {
  padding: 27px 23px;
}
.zhuInput {
  margin-top: 20px;
}
.zhuInput .van-cell {
  padding: 20px 65px;
}
::v-deep .van-field__label {
  font-weight: bold;
  font-size: 24px;
  color: #404040;
}
::v-deep .van-field__control--right {
  font-size: 24px;
  color: #ec6602;
  font-weight: bold;
}
::v-deep .van-field__control::placeholder {
  font-weight: bold;
  color: #d4d4d4;
  font-size: 20px;
}
::v-deep .van-field__label {
  width: 180px;
}
::v-deep .van-field__body {
  font-size: 36px;
  // color: red;
}
.popup-content {
  text-align: center;
  padding: 50px 0;
  padding-bottom: 40px;
  height: 100%;
  width: 100%;
  .contentBox {
    width: 100%;
    height: 80%;
    padding-bottom: 15px;
    overflow: auto;
  }
  .title {
    font-size: 36px;
    color: #000000;
    line-height: 60px;
    font-family: Source Han Sans CN;
  }
  .desc {
    font-size: 24px;
    color: #000000;
    opacity: 0.5;
  }
  .desc1 {
    font-size: 24px;
    color: #000000;
    opacity: 0.5;
    margin-bottom: 30px;
  }
  .ruleText {
    color: #ef0101;
    font-size: 22px;
    line-height: 56px;
    font-weight: bold;
  }
  .value {
    margin-top: 60px;
    // font-size: 48px;
    color: #000000;
    opacity: 0.75;
    line-height: 60px;
  }
  .line {
    margin: 0 auto;
    width: 400px;
    height: 1px;
    background: #868686;
    opacity: 0.5;
  }
  .content-item {
    font-size: 30px;
    font-weight: 500;
    color: #404040;
    line-height: 70px;
    margin-left: 30px;
    span {
      display: inline-block;
      width: 30px;
      height: 20px;
    }
    .checked {
      background: url("./../assets/images/checked.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .content-btn {
    display: inline-block;
    margin: 32px auto;
    bottom: 0;
    width: 200px;
    height: 60px;
    background: #ec6602;
    border-radius: 30px;
    line-height: 60px;
    color: #ffffff;
    font-size: 26px;
    position: absolute;
    bottom: 0;
    left: 33%;
  }
  .zhubtn {
    left: 53%;
  }
  .cancel {
    background: #d3d3d3;
    position: absolute;
    bottom: 0;
    left: 13%;
    color: #000000;
  }
}
.nextStepBtn {
  width: 224px;
  height: 70px;
  background: #ec6602;
  border-radius: 35px;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #ffffff;
  margin-top: 30px;
  margin-left: 67%;
}
.zhucollapse {
  width: 100%;
  height: 77px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  .title {
    font-size: 26px;
    font-weight: 500;
    color: #000000;
    line-height: 77px;
  }
  .zhuSwitch {
    margin-top: 22px;
  }
}
.kongcollapse {
  width: 100%;
  height: 77px;
  background-color: #fff;
  // margin-bottom: 10px;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  .title {
    font-size: 26px;
    font-weight: 500;
    color: #000000;
    line-height: 77px;
  }
  .zhuSwitch {
    margin-top: 22px;
  }
}
.danger .danger-item {
  padding-left: 46px;
  padding-right: 46px;
  text-align: left;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #404040;
  line-height: 40px;
  margin-top: 20px;
  &:nth-child(2) {
    margin-top: 40px;
  }
}
.danger-item {
  float: left;
  padding: 0 18px;
  color: #ef0101;
  font-weight: bold;
}
.van-button--info {
  border: 0;
}
::v-deep .van-popup--center {
  top: 41%;
}
</style>
